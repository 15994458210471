import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { useFetchCurrentBranchTimezone } from '@expane/data'
import { useFetchMyEmployee } from 'gql/employee'
import { Spinner } from '@expane/ui'
import { observer } from 'mobx-react-lite'

export const AccountDeletionWidget = observer(() => {
  const { t } = useTranslation()

  const branchId = store.branch.branchId
  const businessId = store.me.businessId

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: myEmployee, isLoading } = useFetchMyEmployee(timezone, branchId)

  const mailtoURL = useMemo(() => {
    if (!myEmployee) return undefined

    const email = myEmployee.email
    const ids = `bID${businessId}/eID:${myEmployee.id}`

    const to = 'help.expane@gmail.com'
    const subject = `${t('accountDeletion.subject')}: ${email} [${ids}]`
    const body = t('accountDeletion.body', { email, ids })

    return `mailto:${to}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
  }, [myEmployee, businessId, t])

  return (
    <div className="flex-1 flex-centered mt-10">
      {isLoading ? (
        <Spinner expandCentered />
      ) : mailtoURL ? (
        <a
          className="text-center text-xs text-error-200 hover:underline"
          href={mailtoURL}
          target="_blank"
        >
          {t('accountDeletion.action')}
        </a>
      ) : null}
    </div>
  )
})
