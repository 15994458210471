import {
  ClientByIdType,
  ClientPhones,
  ServerClientBriefType,
  ServerClientRelationInsertInput,
  ServerLeadType,
  ServerTagType,
  useCreateClient,
  useUpdateClient,
} from '@expane/data'
import {
  addPhonesToClients,
  ClientWithPhones,
  Gender,
  getIsClientArchived,
} from '@expane/logic/client'
import { useUpdateFile } from '@expane/logic/file'
import { ItemWithModules, useBusinessModulesSettings } from '@expane/logic/modules'
import { permissions } from '@expane/logic/permission'
import { transformPersonName } from '@expane/logic/utils'
import {
  CloseButton,
  CommonPlaceholderDialogProps,
  Dialog,
  Modal,
  PlaceholderDialog,
  PlaceholderInput,
  useShowConfirmationPopup,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { DialogProps, OnCreateFunc } from 'logic/hooks/useOpenDialog'
import { FC, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoCashOutline } from 'react-icons/io5'
import { store } from 'store'
import { HorizontalTabs } from 'ui/HorizontalTabs'
import { PlaceholderEditablePhoto, PlaceholderRangePicker } from 'ui/PlaceholderContent'
import { ButtonWithBillingCheck, QuickSaleButton, SaveButton } from 'widgets/Buttons'
import { InfoTabClientSubscriptionPlaceholder } from 'widgets/ClientDialog/InfoTab/InfoTabClientSubscription'
import { useOpenAddToClientAccountDialog } from 'widgets/OperationsWithClientAccount/AddToClientAccountDialog'
import { useQuickSaleDialog } from 'widgets/QuickSaleDialog'
import { ClientDialogInitialData, OnCreateClientDto } from '.'
import { ClientDuplicateConfirmation } from './ClientDuplicateConfirmation'
import { ClientDialogCommentsTab } from './CommentsTab'
import { ClientDialogDocumentsTab } from './DocumentsTab'
import { ClientDialogGiftCardsTab } from './GiftCardsTab'
import { ClientDialogHistoryTab } from './HistoryTab'
import { ClientDialogInfoTab, ClientInfoFormValues } from './InfoTab'
import { CLIENT_RELATION_MAP } from './InfoTab/ClientRelations'
import {
  transformFormValuesForMutation,
  transformPreferredConnectionsForForms,
} from './InfoTab/SelectPreferredConnections'
import { OptionsTab } from './OptionsTab'
import { ClientDialogSubscriptionTab } from './SubscriptionTab'

interface ClientTabs {
  id: number
  label: string
  permissions?: string[]
}

interface ClientDialogLogicProps extends Omit<DialogProps<OnCreateClientDto>, 'id'> {
  clientById: ClientWithPhones<ClientByIdType> | undefined
  myPermissions: string[]
  tags: ServerTagType[]
  leads: ServerLeadType[]
  setClientId: (id: number) => void
  setIsCreate: (isCreate: boolean) => void
  onCreate?: OnCreateFunc<OnCreateClientDto>
  clients: ServerClientBriefType[]
  clientPhones: ClientPhones[]
  timezone: string
}

export type ClientRelation = { clientId: number | undefined; type: number | undefined }

export const ClientDialogLogic: FC<ClientDialogLogicProps & ClientDialogInitialData> = ({
  timezone,
  closeDialog,
  isCreate,
  clientById,
  myPermissions,
  leads,
  tags,
  setClientId,
  setIsCreate,
  onCreate,
  clients,
  initialData,
  clientPhones,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const branchId = store.branch.branchId!
  const [openSnackbar] = useSnackbar()
  const { t } = useTranslation()

  const { enabledModules } = useBusinessModulesSettings()

  const relationsWhereClientIsFirst: ClientRelation[] =
    clientById?.clientRelationsWhereClientIsFirst
      ? clientById?.clientRelationsWhereClientIsFirst.map(({ type, secondClient }) => ({
          type: type,
          clientId: secondClient.id,
        }))
      : []
  const relationsWhereClientIsSecond: ClientRelation[] =
    clientById?.clientRelationsWhereClientIsSecond
      ? clientById.clientRelationsWhereClientIsSecond.map(({ type, firstClient }) => ({
          type: type === CLIENT_RELATION_MAP.parent.id ? CLIENT_RELATION_MAP.child.id : type,
          clientId: firstClient.id,
        }))
      : []

  const defaultRelations = [...relationsWhereClientIsFirst, ...relationsWhereClientIsSecond]

  const defaultTags = clientById?.clientTags?.map(el => el.tag)

  const {
    formState: clientInfoTabFormState,
    handleSubmit: clientInfoTabHandleSubmit,
    control: clientInfoTabControl,
    setValue: clientInfoTabSetValue,
  } = useForm<ClientInfoFormValues>({
    defaultValues: {
      relations: defaultRelations,
      firstName: clientById?.firstName ?? '',
      lastName: clientById?.lastName ?? '',
      middleName: clientById?.middleName ?? '',
      photo: clientById?.photo ?? '',
      phone: (clientById?.phone || initialData?.phone) ?? '',
      altPhone: clientById?.altPhone ?? '',
      gender: clientById?.gender as Gender,
      birthDate: clientById?.birthDate ?? undefined,
      maximumDebt: clientById?.maximumDebt?.toString() ?? '0',
      lead: clientById?.lead?.id ?? null,
      tags: defaultTags,
      preferredConnections: clientById ? transformPreferredConnectionsForForms(clientById) : [],
      managingEmployeeId: clientById?.managingEmployeeId ?? null,
      email: clientById?.email ?? '',
    },
  })

  const clientTabs: Array<ItemWithModules<ClientTabs>> = [
    {
      id: 0,
      label: 'information',
    },
    {
      id: 1,
      label: 'subscription.name',
      permissions: [permissions.card.get],
      modules: ['subscriptions'],
    },
    {
      id: 2,
      label: 'history',
      permissions: [permissions.transaction.get, permissions.service.get],
    },
    {
      id: 3,
      label: 'comments',
      permissions: [permissions.clientNote.get],
    },
    {
      id: 4,
      label: 'giftCard.name',
      permissions: [permissions.card.get],
      modules: ['giftCards'],
    },
    { id: 5, label: 'documents' },
    {
      id: 6,
      label: 'options',
      permissions: [permissions.client.set],
    },
  ]

  const transformedClientTabs = clientTabs.map(({ label, ...rest }) => ({
    ...rest,
    label: t(label),
  }))

  const disabledTabs = transformedClientTabs
    .filter(
      tab =>
        (tab.permissions ? !tab.permissions.every(p => myPermissions.includes(p)) : false) ||
        (tab.modules
          ? !tab.modules.every(moduleKey => enabledModules?.includes(moduleKey))
          : false),
    )
    .map(({ id }) => id)

  const [activeTabId, setActiveTabId] = useState(transformedClientTabs[0].id)

  const isClientArchived =
    branchId && clientById ? getIsClientArchived(clientById, branchId) : false

  const archived = Boolean(!isCreate && isClientArchived)

  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(
    clientInfoTabFormState,
    closeDialog,
  )

  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

  const { mutateAsync: createMutation } = useCreateClient()
  const { mutateAsync: updateMutation } = useUpdateClient()
  const updatePhoto = useUpdateFile()

  const isDirtyRelations = clientInfoTabFormState.dirtyFields.relations

  const mutateClientInfo =
    (openDialogOnCreateType?: 'addToAccount' | 'quickSale'): SubmitHandler<ClientInfoFormValues> =>
    async (data: ClientInfoFormValues) => {
      const mutate = async (openDialogOnCreateType?: 'addToAccount' | 'quickSale') => {
        const urlPhoto = await updatePhoto({
          prevFile: clientById?.photo ?? undefined,
          file: data.photo,
        })

        const clientRelationsWhereClientIsFirst: ServerClientRelationInsertInput[] = data.relations
          .filter(relation => relation.type !== CLIENT_RELATION_MAP.child.id)
          .map(relation => ({
            secondClientId: relation.clientId,
            firstClientId: isCreate ? undefined : clientById?.id,
            type: relation.type,
          }))

        const clientRelationsWhereClientIsSecond: ServerClientRelationInsertInput[] = data.relations
          .filter(relation => relation.type === CLIENT_RELATION_MAP.child.id)
          .map(relation => ({
            firstClientId: relation.clientId,
            secondClientId: isCreate ? undefined : clientById?.id,
            type: CLIENT_RELATION_MAP.parent.id,
          }))

        if (isCreate) {
          const result = await createMutation({
            clientsSetInput: {
              firstName: data.firstName,
              lastName: data.lastName,
              phone: data.phone,
              altPhone: data.altPhone ?? null,
              photo: urlPhoto,
              middleName: data.middleName,
              gender: data.gender,
              birthDate: data.birthDate ?? null,
              maximumDebt: Number(data.maximumDebt),
              leadId: data.lead,
              managingEmployeeId: data.managingEmployeeId ?? null,
              email: data.email.length > 0 ? data.email : null,
              initialBranchId: branchId,
            },
            clientTagArrRelInsertInput: { data: data.tags?.map(t => ({ tagId: t.id })) ?? [] },
            clientRelationsWhereClientIsFirst: clientRelationsWhereClientIsFirst.length
              ? { data: clientRelationsWhereClientIsFirst }
              : undefined,
            clientRelationsWhereClientIsSecond: clientRelationsWhereClientIsSecond.length
              ? { data: clientRelationsWhereClientIsSecond }
              : undefined,
            clientSettings: {
              data: { preferredConnections: transformFormValuesForMutation(data) },
            },
          })
          if (result?.insertClient?.id) {
            openSnackbar(t('client.clientIsSuccessfullyCreated'), 'success')
            onCreate?.(result.insertClient.id, {
              firstName: data.firstName,
              lastName: data.lastName,
            })
            if (openDialogOnCreateType) {
              setClientId(result.insertClient.id)
              setIsCreate(false)
              if (openDialogOnCreateType === 'addToAccount')
                openAddToClientAccountDialog(result.insertClient.id)
              if (openDialogOnCreateType === 'quickSale')
                openQuickSaleDialog(result.insertClient.id)
            }
          } else openSnackbar(t('submitError'), 'error')
        } else if (clientById) {
          const result = await updateMutation({
            id: clientById.id,
            clientSetInput: {
              firstName: data.firstName,
              lastName: data.lastName,
              phone: data.phone,
              altPhone: data.altPhone ?? null,
              photo: urlPhoto ?? null,
              middleName: data.middleName,
              email: data.email.length > 0 ? data.email : null,
              gender: data.gender,
              birthDate: data.birthDate ?? null,
              maximumDebt: Number(data.maximumDebt),
              leadId: data.lead,
              managingEmployeeId: data.managingEmployeeId ?? null,
            },
            clientTagInsertInput:
              data.tags?.map(t => ({ tagId: t.id, clientId: clientById.id })) ?? [],
            clientRelationInsertInput: isDirtyRelations
              ? [...clientRelationsWhereClientIsFirst, ...clientRelationsWhereClientIsSecond]
              : undefined,
            clientSettingSetInput: { preferredConnections: transformFormValuesForMutation(data) },
          })

          if (result?.updateClientById?.id) {
            openSnackbar(t('client.clientIsSuccessfullyUpdated'), 'success')
          } else openSnackbar(t('submitError'), 'error')
        }
        if (!openDialogOnCreateType) closeDialog()
      }

      const clientsWithPhones = addPhonesToClients(clients, clientPhones)
      const duplicateClients = getDuplicatedClients({
        isCreate,
        clientsWithPhones,
        clientId: clientById?.id,
        clientPhone: data.phone,
      })
      if (duplicateClients.length > 0 && data.phone !== clientById?.phone) {
        showConfirmation({
          title: t('saveConfirmation'),
          description: (
            <ClientDuplicateConfirmation duplicateClients={duplicateClients} phone={data.phone} />
          ),
          onConfirm: () => mutate(openDialogOnCreateType),
        })
      } else {
        return mutate(openDialogOnCreateType)
      }
    }

  const handleOpenAddToAccountDialog = async () => {
    if (isCreate) await clientInfoTabHandleSubmit(mutateClientInfo('addToAccount'))()
    else openAddToClientAccountDialog(clientById?.id)
  }

  const handleOpenQuickSaleDialog = async () => {
    if (isCreate) await clientInfoTabHandleSubmit(mutateClientInfo('quickSale'))()
    else openQuickSaleDialog(clientById?.id)
  }

  const { openAddToClientAccountDialog, addToClientAccountDialog } =
    useOpenAddToClientAccountDialog()

  const { openQuickSaleDialog, quickSaleDialog } = useQuickSaleDialog()

  const isEditingAllowed = myPermissions.includes(permissions.client.set)
  const isWorkWithFinancesAllowed = myPermissions.includes(permissions.transaction.set)
  const isCardEditingAllowed = myPermissions.includes(permissions.card.set)
  const isClientMessagesGetAllowed = myPermissions.includes(permissions.clientMessage.get)

  const disabledSubmit =
    transformedClientTabs[activeTabId].id !== 0 ||
    !clientInfoTabFormState.isDirty ||
    clientInfoTabFormState.isSubmitting
  const saveUnavailable = !isEditingAllowed || archived

  let dialogTitle = t('client.name')
  if (!isCreate && clientById && activeTabId !== 0)
    dialogTitle += ': ' + transformPersonName(clientById)

  return (
    <>
      <Modal
        close={closePopups}
        confirm={() => {
          if (!saveUnavailable && !disabledSubmit) clientInfoTabHandleSubmit(mutateClientInfo())()
        }}
        animation="onlyFadeOut"
      >
        <Dialog>
          <HorizontalTabs
            tabs={isCreate ? [transformedClientTabs[0]] : transformedClientTabs}
            activeTabId={activeTabId}
            disabledTabs={disabledTabs}
            setActiveTabId={setActiveTabId}
            title={dialogTitle}
          />
          <Dialog.Body className="w-256 h-122">
            {activeTabId === transformedClientTabs[0].id && (
              <ClientDialogInfoTab
                timezone={timezone}
                client={clientById}
                isCreate={isCreate}
                disabled={!isEditingAllowed || archived}
                control={clientInfoTabControl}
                tags={tags}
                leads={leads}
                myPermissions={myPermissions}
                setValue={clientInfoTabSetValue}
                setIsCreate={setIsCreate}
              />
            )}
            {clientById && (
              <>
                {activeTabId === transformedClientTabs[1].id && (
                  <ClientDialogSubscriptionTab
                    clientId={clientById.id}
                    isCardEditingAllowed={isCardEditingAllowed}
                  />
                )}
                {activeTabId === transformedClientTabs[2].id && (
                  <ClientDialogHistoryTab
                    client={clientById}
                    isRefundAllowed={isWorkWithFinancesAllowed}
                    isClientMessagesAllowed={isClientMessagesGetAllowed}
                  />
                )}
                {activeTabId === transformedClientTabs[3].id && (
                  <ClientDialogCommentsTab
                    client={clientById}
                    disabled={archived}
                    myPermission={myPermissions}
                  />
                )}
                {activeTabId === transformedClientTabs[4].id && (
                  <ClientDialogGiftCardsTab
                    clientId={clientById.id}
                    disabled={archived}
                    myPermissions={myPermissions}
                  />
                )}
                {activeTabId === transformedClientTabs[5].id && (
                  <ClientDialogDocumentsTab
                    clientId={clientById.id}
                    isEditingAllowed={isEditingAllowed}
                  />
                )}
                {activeTabId === transformedClientTabs[6].id && (
                  <OptionsTab
                    client={clientById}
                    disabled={!isEditingAllowed}
                    archived={archived}
                    closeDialog={closeDialog}
                  />
                )}
              </>
            )}
          </Dialog.Body>
          <Dialog.Footer>
            {saveUnavailable ? null : (
              <SaveButton
                onClick={clientInfoTabHandleSubmit(mutateClientInfo())}
                disabled={disabledSubmit}
                spinner={clientInfoTabFormState.isSubmitting}
                isCreate={isCreate}
              />
            )}

            <CloseButton onClick={closePopups} />
            {!archived && isWorkWithFinancesAllowed && (
              <div className="mr-auto flex gap-2">
                <ButtonWithBillingCheck
                  type="outline"
                  onClick={handleOpenAddToAccountDialog}
                  Icon={IoCashOutline}
                >
                  {t('addToAccount')}
                </ButtonWithBillingCheck>

                <QuickSaleButton onClick={handleOpenQuickSaleDialog} shortText />
              </div>
            )}
          </Dialog.Footer>
        </Dialog>
      </Modal>
      {addToClientAccountDialog}
      {quickSaleDialog}
      {confirmPopup}
      {confirmationModal}
    </>
  )
}

export const ClientDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({ closeDialog }) => {
  const { t } = useTranslation()

  return (
    <PlaceholderDialog title={t('client.name')} className="w-256 h-122" closeDialog={closeDialog}>
      <div className="flex">
        <div className="flex flex-col justify-between w-3/4">
          <div className="flex mb-4">
            <PlaceholderInput label={t('lastName')} className="w-1/3 pr-2" />
            <PlaceholderInput label={t('firstName')} className="w-1/3 pr-2" />
            <PlaceholderInput label={t('middleName')} className="w-1/3 pr-2" />
          </div>

          <div className="flex mb-8">
            <PlaceholderInput label={t('phone')} className="w-1/3 pr-2" />
            <PlaceholderRangePicker label={t('gender.name')} className="w-1/3 pr-2" />
            <PlaceholderInput label={t('birthDate')} className="w-1/3 pr-2" />
          </div>
        </div>

        <div className="w-1/4 flex flex-col">
          <PlaceholderEditablePhoto className="flex justify-center" size="medium" />
        </div>
      </div>
      <div className="flex mt-2 items-start">
        <div className="w-3/5 mr-3">
          <div className="flex justify-between pb-4">
            <PlaceholderInput className="w-1/2 pr-2 shrink-0" label={t('email')} />
            <PlaceholderInput label={t('tag.name')} className="w-1/2" />
          </div>

          <div className="flex justify-between">
            <PlaceholderInput label={t('maximumDebt')} className="w-1/3 pr-2" />
            <PlaceholderInput label={t('preferredConnections')} className="w-1/3 pr-2" />
            <PlaceholderInput label={t('lead')} className="w-1/3" />
          </div>
        </div>

        <InfoTabClientSubscriptionPlaceholder className="w-2/5" />
      </div>
    </PlaceholderDialog>
  )
}

const getDuplicatedClients = (dto: {
  isCreate: boolean
  clientPhone: string
  clientId: number | undefined
  clientsWithPhones: ClientWithPhones<ServerClientBriefType>[]
}) => {
  const { clientId, clientPhone, clientsWithPhones, isCreate } = dto
  if (isCreate) {
    return clientsWithPhones.filter(el => el.phone && el.phone === clientPhone)
  } else {
    return clientsWithPhones.filter(el => el.phone === clientPhone && el.id !== clientId)
  }
}
