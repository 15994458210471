import {
  ClientByIdType,
  ServerLeadType,
  ServerTag,
  ServerTagType,
  useFetchClientBalance,
  useFetchClientsBriefs,
  useFetchExtendedEmployees,
} from '@expane/data'
import { createCurrentDate, isBefore, startOfDay } from '@expane/date'
import type { ClientWithPhones, Gender } from '@expane/logic/client'
import { useConvertNumberToMoneyCode } from '@expane/logic/currency'
import { checkOnlyPositiveAmount, PLACEHOLDERS, validateEmail } from '@expane/logic/form'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import { permissions } from '@expane/logic/permission'
import { checkValidPhone } from '@expane/logic/phone'
import { Input, InputLabel, SelectDropdown, usePopupOpenState } from '@expane/ui'
import { DateTimePicker } from '@expane/widgets'
import { showPhoneErrorMessage } from 'logic/form'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { onChangeWithFirstUpperCase } from 'logic/ui'
import { transformPersonsForSelect } from 'logic/utils'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Control, Controller, UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoAddCircleOutline, IoChevronBackOutline } from 'react-icons/io5'
import { store } from 'store'
import { EditButton } from 'ui/EditButton'
import { PhoneInput } from 'ui/PhoneInput'
import { TagSelect } from 'ui/TagSelect'
import { ExtendedSelectDropdownItem } from 'widgets/ClientDialog'
import { ClientRelation } from 'widgets/ClientDialog/ClientDialogLogic'
import { ClientRelations } from 'widgets/ClientDialog/InfoTab/ClientRelations'
import { InfoTabClientSubscription } from 'widgets/ClientDialog/InfoTab/InfoTabClientSubscription'
import { SelectPreferredConnections } from 'widgets/ClientDialog/InfoTab/SelectPreferredConnections'
import { EditablePhoto } from 'widgets/EditablePhoto'
import { EmployeeDialog } from 'widgets/EmployeeDialog'
import { GenderPicker } from 'widgets/GenderPicker'
import { LeadsDialog } from 'widgets/LeadDialog'
import { TagsDialog } from 'widgets/TagDialog'
import { generateEmailErrorMessage, validateClientEmail } from './logic'

export interface ClientDialogInfoTabProps {
  client?: ClientWithPhones<ClientByIdType>
  disabled: boolean
  isCreate: boolean
  control: Control<ClientInfoFormValues>
  setValue: UseFormSetValue<ClientInfoFormValues>
  leads: ServerLeadType[]
  tags: ServerTagType[]
  myPermissions: string[]
  timezone: string
  setIsCreate: (isCreate: boolean) => void
}

export type ClientInfoFormValues = {
  firstName: string
  phone: string
  altPhone?: string
  middleName?: string
  lastName?: string
  photo?: string
  gender?: Gender
  birthDate?: Date
  lead?: number | null
  tags?: Pick<ServerTag, 'id' | 'name' | 'color' | 'description'>[]
  maximumDebt: string
  relations: ClientRelation[]
  preferredConnections: ExtendedSelectDropdownItem[]
  managingEmployeeId?: number | null
  email: string
}

export const ClientDialogInfoTab: FC<ClientDialogInfoTabProps> = observer(
  ({ client, control, disabled, tags, leads, myPermissions, isCreate, setValue, timezone }) => {
    const branchId = store.branch.branchId
    const { isOpen, openPopup, closePopup } = usePopupOpenState()

    const { t } = useTranslation()
    const convertToMoneyCode = useConvertNumberToMoneyCode({ strictNoFraction: true, branchId })

    const { openCreateDialog: openLeadsDialog, dialog: leadsDialog } = useOpenDialog(LeadsDialog)
    const { openCreateDialog: openCreateTagDialog, dialog: tagDialog } = useOpenDialog(TagsDialog)
    const { openEditDialog: openEmployeeDialog, dialog: employeeDialog } =
      useOpenDialog(EmployeeDialog)

    const { data: clients } = useFetchClientsBriefs(branchId, timezone)
    const { data: clientBalance } = useFetchClientBalance(client?.id, branchId)
    const { data: employees } = useFetchExtendedEmployees(timezone, branchId)
    const { getModuleSetting } = useBusinessModulesSettings()

    const areCardsGetAllowed = myPermissions?.includes(permissions.card.get)
    const isLeadEditingAllowed = myPermissions?.includes(permissions.lead.set)
    const isTagsCreateAllowed = myPermissions?.includes(permissions.tag.set)
    const isPhoneViewAllowed = myPermissions?.includes(permissions.client.phone)

    const showManagingEmployee = getModuleSetting('managingEmployee')
    const emailAndTagFieldsWidth = showManagingEmployee ? 'w-1/3' : 'w-1/2'

    const showAltPhone = client?.altPhone || isOpen
    const additionalRowWidth = showAltPhone ? 'w-4/12' : 'w-1/3'

    const isEmailInputDisabled = isCreate ? false : Boolean(client?.email) || disabled

    return (
      <>
        <div className="flex">
          <div className="flex flex-col justify-between w-3/4">
            <div className="flex">
              <Controller
                name="lastName"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    label={t('lastName')}
                    placeholder={t('placeholders.lastName')}
                    containerClassName="w-1/3 pr-2"
                    value={value}
                    disabled={disabled}
                    onChange={onChangeWithFirstUpperCase(onChange)}
                    autoFocus
                  />
                )}
              />

              <Controller
                name="firstName"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value }, formState }) => (
                  <Input
                    label={t('firstName')}
                    placeholder={t('placeholders.firstName')}
                    required
                    errorMessage={{
                      isShown: Boolean(formState.errors.firstName),
                      text: t('formError.required'),
                    }}
                    containerClassName="w-1/3 pr-2"
                    value={value}
                    disabled={disabled}
                    onChange={onChangeWithFirstUpperCase(onChange)}
                  />
                )}
              />

              <Controller
                name="middleName"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    label={t('middleName')}
                    placeholder={t('placeholders.middleName')}
                    containerClassName="w-1/3 pr-2"
                    value={value}
                    disabled={disabled}
                    onChange={onChangeWithFirstUpperCase(onChange)}
                  />
                )}
              />
            </div>

            <div className="flex">
              {isPhoneViewAllowed && (
                <>
                  <div className={`${additionalRowWidth}`}>
                    <Controller
                      name="phone"
                      control={control}
                      rules={{ required: true, validate: checkValidPhone }}
                      render={({ field: { onChange, value }, formState }) => (
                        <PhoneInput
                          label={t('phone')}
                          required
                          placeholder={PLACEHOLDERS.phone}
                          errorMessage={{
                            isShown: Boolean(formState.errors.phone),
                            text: showPhoneErrorMessage(formState.errors.phone?.type ?? '', t),
                          }}
                          value={value}
                          disabled={disabled}
                          onChange={onChange}
                          containerClassName="pr-2"
                        />
                      )}
                    />

                    <div className="flex items-center" onClick={isOpen ? closePopup : openPopup}>
                      {client?.altPhone ? null : isOpen ? (
                        <>
                          <IoChevronBackOutline size="1rem" className="mr-0.5 text-gray-400" />
                          <p className="text-xs cursor-pointer text-gray-400">{t('hide')}</p>
                        </>
                      ) : (
                        <>
                          <IoAddCircleOutline size="1rem" className="mr-0.5 text-gray-400" />
                          <p className="text-xs cursor-pointer text-gray-400">
                            {t('additionalPhone')}
                          </p>
                        </>
                      )}
                    </div>
                  </div>

                  {showAltPhone ? (
                    <Controller
                      name="altPhone"
                      control={control}
                      rules={{ validate: value => (value ? checkValidPhone(value) : true) }}
                      render={({ field: { onChange, value }, formState }) => (
                        <PhoneInput
                          label={t('additionalPhone')}
                          placeholder={PLACEHOLDERS.phone}
                          errorMessage={{
                            isShown: Boolean(formState.errors.altPhone),
                            text: showPhoneErrorMessage(formState.errors.altPhone?.type ?? '', t),
                          }}
                          value={value ?? ''}
                          disabled={disabled}
                          onChange={onChange}
                          containerClassName={`${additionalRowWidth} pr-2`}
                        />
                      )}
                    />
                  ) : null}
                </>
              )}

              <Controller
                control={control}
                name="gender"
                render={({ field: { onChange, value } }) => (
                  <GenderPicker
                    label={t('gender.name')}
                    onChange={onChange}
                    value={value}
                    disabled={disabled}
                    containerClassName={`${showAltPhone ? '' : 'w-1/3'} pr-2`}
                  />
                )}
              />

              <Controller
                control={control}
                name="birthDate"
                rules={{
                  validate: value => {
                    if (!value) return true
                    return isBefore(value, startOfDay(createCurrentDate(timezone)))
                  },
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <DateTimePicker
                    timezone={timezone}
                    label={t('birthDate')}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    type="date"
                    customDatePopupPlacement="bottom-end"
                    isClearable
                    className={`${additionalRowWidth} pr-2`}
                    errorMessage={{
                      isShown: Boolean(error),
                      text: t('clientBirthDateError'),
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className="w-1/4 flex flex-col">
            <Controller
              name="photo"
              control={control}
              render={({ field: { value, onChange } }) => (
                <EditablePhoto
                  defaultPhoto={value}
                  onChange={onChange}
                  disabled={disabled}
                  containerClassName="flex justify-center"
                  size="medium"
                />
              )}
            />

            {client && (
              <div className="flex justify-center mt-1 text-sm">
                <div className="mr-3 text-gray-400">{`${t('onAccount')}:`}</div>

                <div className="text-main-color font-medium">
                  {clientBalance === undefined ? null : convertToMoneyCode(clientBalance)}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex mt-2 items-start">
          <div className="w-3/5 mr-3">
            <div className="flex justify-between">
              {showManagingEmployee && (
                <Controller
                  control={control}
                  name="managingEmployeeId"
                  render={({ field: { value, onChange } }) => (
                    <SelectDropdown
                      label={t('referral')}
                      value={value}
                      items={employees ? transformPersonsForSelect(employees) : []}
                      placeholder={t('placeholders.defaultSelect')}
                      className="w-1/3 pr-2 shrink-0"
                      noDataMessage={t('noSuchEmployee')}
                      onSelectChange={onChange}
                      customClearFunction={() =>
                        setValue('managingEmployeeId', null, { shouldDirty: true })
                      }
                      onEditClick={id => openEmployeeDialog(id)}
                      disabled={disabled}
                      isClearable
                    />
                  )}
                />
              )}

              <Controller
                control={control}
                name="email"
                rules={{
                  validate: {
                    validateClientEmail: email => validateClientEmail(email, clients, client?.id),
                    validateEmail: email => (email ? validateEmail(email) : true),
                  },
                }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Input
                    type="email"
                    value={value}
                    onChange={onChange}
                    containerClassName={emailAndTagFieldsWidth + ' pr-2 shrink-0'}
                    label={t('email')}
                    disabled={isEmailInputDisabled}
                    errorMessage={{
                      isShown: Boolean(error),
                      text: generateEmailErrorMessage(error?.type, t),
                    }}
                  />
                )}
              />

              <div className={emailAndTagFieldsWidth}>
                <InputLabel
                  label={t('tag.name')}
                  onPlusClick={isTagsCreateAllowed ? openCreateTagDialog : undefined}
                />

                <div className="flex items-center">
                  <Controller
                    control={control}
                    name="tags"
                    render={({ field: { value, onChange } }) => (
                      <TagSelect
                        selectedItems={value}
                        items={tags}
                        onItemSelect={onChange}
                        disabled={disabled}
                        placeholder={t('placeholders.tagName')}
                        className="grow"
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-between">
              <Controller
                name="maximumDebt"
                control={control}
                rules={{ validate: checkOnlyPositiveAmount }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    label={t('maximumDebt')}
                    required
                    containerClassName="w-1/3 pr-2"
                    value={value}
                    type={'number'}
                    disabled={disabled}
                    onChange={onChange}
                    errorMessage={{ isShown: Boolean(error), text: t('formError.invalidValue') }}
                  />
                )}
              />
              <Controller
                name="preferredConnections"
                control={control}
                // TODO: почистить клиентов с моковыми данными, чтобы не отправлялись смс на недействительные номера
                // и сделать обязательным полем
                render={({ field: { onChange, value } }) => (
                  <SelectPreferredConnections
                    onChange={onChange}
                    value={value as ExtendedSelectDropdownItem[]}
                    clientById={client}
                    className="w-1/3 pr-2"
                    disabled={disabled}
                  />
                )}
              />

              <div className="w-1/3">
                <InputLabel label={t('lead')} />

                <div className="flex items-center">
                  <Controller
                    control={control}
                    name="lead"
                    render={({ field: { value, onChange } }) => (
                      <SelectDropdown
                        className="grow"
                        value={value}
                        placeholder=""
                        items={leads}
                        noDataMessage={t('noSuchLead')}
                        disabled={disabled}
                        onSelectChange={onChange}
                        customClearFunction={() => setValue('lead', null, { shouldDirty: true })}
                        isClearable
                      />
                    )}
                  />
                  {isLeadEditingAllowed && (
                    <EditButton onClick={() => openLeadsDialog()} className="ml-1" />
                  )}
                </div>
              </div>
            </div>

            <p className="text-sm leading-3 font-medium text-gray-500 mb-2">
              {t('familyRelations')}
            </p>

            <ClientRelations
              clientId={client?.id}
              className="mt-2 h-36"
              control={control}
              disabled={disabled}
            />
          </div>

          {areCardsGetAllowed && (
            <InfoTabClientSubscription className="w-2/5" clientId={client?.id ?? null} />
          )}
        </div>
        {employeeDialog}
        {leadsDialog}
        {tagDialog}
      </>
    )
  },
)
