import {
  EmployeeGroupWithSchedule,
  ServerBranchType,
  ServerLocationGroupType,
  ServerLocationWithServiceIdsType,
  ServerProductType,
  ServerServiceBriefWithProductType,
  ServerServiceByIdType,
  ServerServiceGroupType,
} from '@expane/data'
import { checkOnlyPositiveAmount, PLACEHOLDERS } from '@expane/logic/form'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import { Consumable } from '@expane/logic/payment/booking'
import { permissions } from '@expane/logic/permission'
import {
  checkIfItIsServiceTypeChangingAllowed,
  getEmployeeIdsThatWereUsedHashMap,
  getIsServiceForSale,
  getLocationIdsThatWereUsedHashMap,
  SERVICE_TYPES,
} from '@expane/logic/service'
import { checkIfNameWasNotChanged, translateItemsName } from '@expane/logic/utils'
import {
  CommonPlaceholderDialogProps,
  Dialog,
  Input,
  Modal,
  PlaceholderDialog,
  PlaceholderInput,
  PlaceholderTextarea,
  SelectDropdown,
  SelectDropDownItem,
  Textarea,
  useShowConfirmationPopup,
} from '@expane/ui'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { DialogProps, OnCreateFunc, useOpenDialog } from 'logic/hooks/useOpenDialog'
import {
  DURATIONS,
  provideDefaultSelectedForEmployeeItems,
  provideDisabledForUsedLocationsByIds,
  SERVICE_COLORS_LIST,
} from 'logic/service'
import { FC, useRef } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ColorPicker } from 'ui/ColorPicker'
import { InputWithDropDownMenu } from 'ui/InputWithDropDownMenu'
import { PlaceholderEditablePhoto } from 'ui/PlaceholderContent'
import { TreeMenuItem } from 'ui/TreeMenu'
import { transformDataForTreeMenu } from 'ui/TreeMenu/logic.common'
import { convertEmployeeGroupsToTreeItems } from 'ui/TreeMenu/logic.employee'
import { EditablePhoto } from 'widgets/EditablePhoto'
import { OnCreateServiceDto } from 'widgets/ServiceDialog/index'
import { ServiceDialogFooter } from 'widgets/ServiceDialog/ServiceDialogFooter'
import { ServiceDialogLocationsEmployeesInputs } from 'widgets/ServiceDialog/ServiceDialogLogic/LocationsEmployeesInputs'
import { ServiceConsumablesInputs } from 'widgets/ServiceDialog/ServiceDialogLogic/ServiceConsumablesInputs'
import { ServicePermissionsInputs } from 'widgets/ServiceDialog/ServiceDialogLogic/ServicePermissionsInputs'
import { ServiceGroupDialog } from 'widgets/ServiceGroupDialog'
import { getDefaultEmployees, getDefaultLocations, getDefaultServicePermissions } from './logic'
import { Checkbox } from '@expane/ui'

export const ServiceDialogLogic: FC<ServiceDialogLogicProps> = ({
  isCreate,
  serviceById,
  serviceGroups,
  employeeGroups,
  branch,
  locations,
  myPermissions,
  products,
  closeDialog,
  services,
  onCreate,
  locationGroups,
  areManyEmployeesAllowed,
}) => {
  const { t } = useTranslation()

  const { getModuleSetting, getFilteredItemsByModules } = useBusinessModulesSettings()
  const isConsumablesEnabled = getModuleSetting('consumables')

  const branchId = branch.id
  // В созданных бранчах всегда есть locationId
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const branchLocationId = branch.locationId!
  const submitForm = useRef<(() => void) | undefined>(undefined)

  const employeeTreeMenuItems = convertEmployeeGroupsToTreeItems(employeeGroups)
  const employeeIdsHashMap = getEmployeeIdsThatWereUsedHashMap(serviceById)
  const employeesWithDisabled = provideDefaultSelectedForEmployeeItems(
    employeeTreeMenuItems,
    employeeIdsHashMap,
    t,
  )

  const locationTreeItems = transformDataForTreeMenu(locationGroups, locations)
  const locationIdsHashMap = getLocationIdsThatWereUsedHashMap(serviceById)
  const locationsWithDisabled = provideDisabledForUsedLocationsByIds(
    locationTreeItems,
    locationIdsHashMap,
    t,
  )

  const { formState, control, handleSubmit, setValue } = useForm<ServiceDialogFormData>({
    defaultValues: {
      name: serviceById?.name ?? '',
      groupId: serviceById?.groupId ?? undefined,
      defaultDuration: serviceById?.defaultDuration.toString() ?? '',
      description: serviceById?.description ?? '',
      picture: serviceById?.picture ?? '',
      color: serviceById?.color ?? undefined,
      price: serviceById?.price.toString() ?? '',
      costPrice: serviceById?.costPrice.toString() ?? '',
      type: serviceById?.type,
      locations: getDefaultLocations(serviceById, locationsWithDisabled),
      servicePermissions: getDefaultServicePermissions(serviceById, employeeTreeMenuItems),
      employees: getDefaultEmployees(serviceById, employeesWithDisabled),
      consumables:
        serviceById?.serviceProducts.map(service => ({
          productId: service.product.id,
          quantity: service.quantity.toString(),
        })) ?? [],
      availableForClient: serviceById?.availableForClient ?? true,
    },
  })

  const watchedName = useWatch({
    control,
    name: 'name',
  })

  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)
  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()
  const { openCreateDialog: openGroupDialog, dialog: groupDialog } =
    useOpenDialog(ServiceGroupDialog)

  const isEditingAllowed =
    myPermissions.includes(permissions.service.set) && !(isCreate ? false : serviceById?.archived)

  const isCopiedAllowed = !checkIfNameWasNotChanged(serviceById?.name ?? '', watchedName)

  const isServiceTypeChangingAllowed = checkIfItIsServiceTypeChangingAllowed(serviceById)

  const watchedType = useWatch({ control, name: 'type' })
  const isForSale = getIsServiceForSale(watchedType)

  return (
    <>
      <Modal close={closePopups} confirm={submitForm.current} animation="onlyFadeOut">
        <Dialog>
          <Dialog.Title>{t('service.name')}</Dialog.Title>

          <Dialog.Body className="flex w-256">
            <div className="flex-1 mr-2">
              <div className="flex">
                <div className="w-1/2">
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        label={t('title')}
                        placeholder={t('placeholders.serviceName')}
                        required={isForSale}
                        containerClassName="mr-2"
                        errorMessage={{
                          isShown: Boolean(formState.errors.name),
                          text: t('formError.required'),
                        }}
                        value={value}
                        onChange={onChange}
                        disabled={!isEditingAllowed}
                        autoFocus
                      />
                    )}
                  />
                </div>

                <Controller
                  control={control}
                  name="groupId"
                  render={({ field: { onChange, value } }) => (
                    <SelectDropdown
                      label={t('category')}
                      items={serviceGroups}
                      placeholder={t('placeholders.defaultSelect')}
                      noDataMessage={t('noCategory')}
                      className="w-1/2"
                      onSelectChange={onChange}
                      value={value ?? undefined}
                      errorMessage={{
                        isShown: Boolean(formState.errors.groupId),
                        text: t('formError.findDurations'),
                      }}
                      disabled={!isEditingAllowed}
                      isClearable
                      customClearFunction={() => setValue('groupId', null, { shouldDirty: true })}
                      onPlusClick={
                        isEditingAllowed ? () => openGroupDialog(id => onChange(id)) : undefined
                      }
                    />
                  )}
                />
              </div>

              <ServiceDialogLocationsEmployeesInputs
                control={control}
                employeeTreeMenuItemsWithDisabled={employeesWithDisabled}
                disabled={!isEditingAllowed}
                myPermissions={myPermissions}
                locationTreeItemsWithDisabled={locationsWithDisabled}
                areManyEmployeesAllowed={areManyEmployeesAllowed}
              />

              <div className="flex">
                <Controller
                  control={control}
                  name="defaultDuration"
                  rules={{ required: !isForSale, validate: checkOnlyPositiveAmount }}
                  render={({ field: { onChange, value } }) => (
                    <InputWithDropDownMenu
                      label={t('defaultDuration.name')}
                      items={DURATIONS}
                      value={value}
                      onInputChange={onChange}
                      placeholder={PLACEHOLDERS.subscriptionDurations}
                      disabled={!isEditingAllowed || isForSale}
                      type="number"
                      hint={t('defaultDuration.hint')}
                      className="flex-1 mr-2"
                      errorMessage={{
                        isShown: Boolean(formState.errors.defaultDuration),
                        text:
                          formState.errors?.defaultDuration?.type === 'required'
                            ? t('formError.required')
                            : t('formError.invalidValue'),
                        reserveIndent: true,
                      }}
                      required
                    />
                  )}
                />
                <ServicePermissionsInputs
                  control={control}
                  disabled={!isEditingAllowed}
                  employeeTreeMenuItems={employeeTreeMenuItems}
                  className="w-1/2"
                />
              </div>
              <div className="flex">
                <Controller
                  name="description"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Textarea
                      label={t('description')}
                      containerClassName="w-4/6 mr-2"
                      placeholder={t('placeholders.serviceDescription')}
                      value={value}
                      onChange={onChange}
                      disabled={!isEditingAllowed}
                    />
                  )}
                />

                <Controller
                  name="picture"
                  control={control}
                  defaultValue={serviceById?.picture ?? undefined}
                  render={({ field: { value, onChange } }) => (
                    <EditablePhoto
                      containerClassName="flex-centered order-last"
                      defaultPhoto={value}
                      onChange={onChange}
                      disabled={!isEditingAllowed}
                      size="small"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="color"
                  render={({ field: { value, onChange } }) => (
                    <ColorPicker
                      value={value}
                      label={t('color')}
                      hint={t('colorHint')}
                      onChange={onChange}
                      colors={SERVICE_COLORS_LIST.map(color => color.bgColor)}
                      disabled={!isEditingAllowed}
                      clearFunction={() => setValue('color', undefined, { shouldDirty: true })}
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex">
                <Controller
                  control={control}
                  name="type"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <SelectDropdown
                      label={t('type')}
                      required={isServiceTypeChangingAllowed}
                      placeholder={t('placeholders.defaultSelect')}
                      hint={isServiceTypeChangingAllowed ? undefined : t('serviceTypeHint')}
                      items={translateItemsName(getFilteredItemsByModules(SERVICE_TYPES), t)}
                      onSelectChange={onChange}
                      className="w-1/3"
                      value={value}
                      disabled={!isEditingAllowed || (!isServiceTypeChangingAllowed && !isCreate)}
                      errorMessage={{
                        isShown: Boolean(formState.errors.type),
                        text: t('formError.required'),
                      }}
                    />
                  )}
                />

                <Controller
                  name="price"
                  control={control}
                  rules={{ required: true, validate: checkOnlyPositiveAmount }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      type="number"
                      label={t('price')}
                      placeholder={PLACEHOLDERS.servicePrice}
                      containerClassName="pl-2 w-1/3"
                      required
                      errorMessage={{
                        isShown: Boolean(formState.errors.price),
                        text:
                          formState.errors?.price?.type === 'required'
                            ? t('formError.required')
                            : t('formError.invalidValue'),
                      }}
                      value={value}
                      onChange={onChange}
                      disabled={!isEditingAllowed}
                    />
                  )}
                />

                <Controller
                  name="costPrice"
                  control={control}
                  rules={{ validate: checkOnlyPositiveAmount }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      type="number"
                      label={t('costPrice')}
                      placeholder={PLACEHOLDERS.servicePrice}
                      containerClassName="pl-2 w-1/3"
                      value={value}
                      onChange={onChange}
                      disabled={!isEditingAllowed}
                      errorMessage={{
                        isShown: Boolean(formState.errors.costPrice),
                        text: t('formError.invalidValue'),
                      }}
                    />
                  )}
                />
              </div>
              {isConsumablesEnabled && (
                <ServiceConsumablesInputs
                  control={control}
                  products={products}
                  disabled={!isEditingAllowed}
                  className="max-w-full max-h-72 overflow-x-auto"
                />
              )}
            </div>
          </Dialog.Body>

          <Dialog.Footer>
            <ServiceDialogFooter
              serviceById={serviceById}
              services={services}
              handleSubmit={handleSubmit}
              branchId={branchId}
              branchLocationId={branchLocationId}
              closeDialog={closeDialog}
              closePopups={closePopups}
              showConfirmation={showConfirmation}
              isCreate={isCreate}
              isEditingAllowed={isEditingAllowed}
              formState={formState}
              disabledCopy={!isCopiedAllowed || formState.isSubmitting}
              onSubmitForm={submitForm}
              onCreate={onCreate}
            />
          </Dialog.Footer>
        </Dialog>
      </Modal>
      {groupDialog}
      {confirmPopup}
      {confirmationModal}
    </>
  )
}

export const ServiceDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({ closeDialog }) => {
  const { t } = useTranslation()

  return (
    <PlaceholderDialog title={t('service.name')} className="flex w-256" closeDialog={closeDialog}>
      <div className="flex-1 mr-2">
        <div className="flex mb-4">
          <PlaceholderInput label={t('title')} className="w-1/2 mr-2" />
          <PlaceholderInput label={t('category')} className="w-1/2" />
        </div>

        <div>
          <Checkbox containerClassName="w-1/2" label={t('availableForClient.name')} disabled />
          <PlaceholderInput label={t('locations.name')} className="mt-2 mb-4" />
          <PlaceholderInput label={t('employee.name')} className="mb-4" />
        </div>

        <div className="flex mb-4">
          <PlaceholderInput label={t('defaultDuration.name')} className="flex-1 mr-2" />
          <PlaceholderInput label={t('whoCanSellService')} className="w-1/2" />
        </div>
        <div className="flex">
          <PlaceholderTextarea label={t('description')} className="w-4/6 mr-2" />

          <PlaceholderEditablePhoto className="flex-centered order-last" size="small" />
          <ColorPicker
            value={undefined}
            label={t('color')}
            onChange={() => {
              return
            }}
            colors={SERVICE_COLORS_LIST.map(color => color.bgColor)}
            disabled
          />
        </div>
      </div>
      <div className="flex-1">
        <div className="flex">
          <PlaceholderInput label={t('type')} className="w-1/3" />
          <PlaceholderInput label={t('price')} className="pl-2 w-1/3" />
          <PlaceholderInput label={t('costPrice')} className="pl-2 w-1/3" />
        </div>
      </div>
    </PlaceholderDialog>
  )
}

interface ServiceDialogLogicProps extends Omit<DialogProps<OnCreateServiceDto>, 'id'> {
  myPermissions: string[]
  serviceById: ServerServiceByIdType | undefined
  employeeGroups: EmployeeGroupWithSchedule[]
  locations: ServerLocationWithServiceIdsType[]
  serviceGroups: ServerServiceGroupType[]
  branch: ServerBranchType
  products: ServerProductType[]
  services: ServerServiceBriefWithProductType[]
  onCreate?: OnCreateFunc<OnCreateServiceDto>
  locationGroups: ServerLocationGroupType[]
  areManyEmployeesAllowed: boolean
}

export interface ServiceDialogFormData {
  isItCopy: boolean
  name: string
  defaultDuration: string
  description: string
  price: string
  costPrice: string
  picture: string
  // null is used because of the react-form bug when groupId is not updated properly if set to undefined from number
  groupId: number | null
  servicePermissions: TreeMenuItem[]
  locations: SelectDropDownItem[]
  employees: TreeMenuItem[]
  consumables: Consumable[]
  color: string | undefined
  type: number
  availableForClient: boolean
}
