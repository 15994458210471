import { UseFormSetValue } from 'react-hook-form'
import {
  PermissionName,
  PermissionPageFormData,
  PermissionPageRowProps,
  PermissionsInFormData,
} from '@expane/logic/permission/logic'

export interface PermissionPageBlockProps extends PermissionPageRowProps {
  setValue: UseFormSetValue<PermissionPageFormData>
  checkRelationBulk: (props: CheckRelationBulkProps) => void
}

export const allSelectedPermissions: PermissionsInFormData = {
  client: { get: true, set: true, phone: true, archive: true },
  clientNote: { get: true, set: true },
  clientMessage: { get: true, set: true },
  business: { set: true },
  billingInfo: { get: true, set: true },
  branch: { set: true, getAll: true },
  employee: {
    get: true,
    set: true,
    email: true,
    archive: true,
  },
  booking: {
    get: true,
    set: true,
    setOwn: true,
    editPast: true,
  },
  bookingStatus: {
    set: true,
    unset: true,
  },
  service: {
    get: true,
    set: true,
    archive: true,
  },
  account: { get: true, set: true },
  storage: { get: true, set: true },
  salary: { get: true, set: true },
  rolePermission: { set: true },
  inventory: { get: true, set: true },
  product: { get: true, set: true, archive: true },
  schedule: { get: true, set: true, editPast: true },
  supplier: { get: true, set: true },
  card: { get: true, set: true },
  waitingList: { get: true, set: true },
  report: {
    revenueExpenses: true,
    revenueByAccount: true,
    saleOfServices: true,
    saleOfCards: true,
    saleOfProducts: true,
    leadsOfClients: true,
    clientsVisits: true,
    incomingPayments: true,
    outgoingPayments: true,
  },
  review: { get: true, set: true },
  lead: { set: true },
  tag: { set: true },
  revenueReason: { set: true },
  expensesReason: { set: true },
  movement: { get: true, set: true },
  transaction: {
    get: true,
    set: true,
    editPast: true,
    betweenAccounts: true,
    setRefund: true,
  },
  timeOff: { get: true, set: true },
  timeOffReason: { set: true },
  location: { get: true, set: true, archive: true },
  analytics: { get: true },
  notification: { get: true, billing: true },
}

export interface Relation {
  name: PermissionName
  selected: boolean
  dependsOn: PermissionName[]
}

export interface CheckRelationBulkProps {
  onConfirm: () => void
  permissions: PermissionName[]
}
