import { ServerRolePermissionInsertInput, ServerRolePermissionType } from '@expane/data'
import { Control } from 'react-hook-form'

export interface PermissionsInFormData {
  client: { get: boolean; set: boolean; phone: boolean; archive: boolean }
  clientNote: { get: boolean; set: boolean }
  clientMessage: { get: boolean; set: boolean }
  business: { set: boolean }
  billingInfo: { get: boolean; set: boolean }
  branch: { set: boolean; getAll: boolean }
  employee: {
    get: boolean
    set: boolean
    email: boolean
    archive: boolean
  }
  booking: {
    get: boolean
    set: boolean
    setOwn: boolean
    editPast: boolean
  }
  bookingStatus: {
    set: boolean
    unset: boolean
  }
  service: {
    get: boolean
    set: boolean
    archive: boolean
  }
  account: { get: boolean; set: boolean }
  storage: { get: boolean; set: boolean }
  salary: { get: boolean; set: boolean }
  rolePermission: { set: boolean }
  inventory: { get: boolean; set: boolean }
  product: { get: boolean; set: boolean; archive: boolean }
  schedule: { get: boolean; set: boolean; editPast: boolean }
  supplier: { get: boolean; set: boolean }
  card: { get: boolean; set: boolean }
  waitingList: { get: boolean; set: boolean }
  report: {
    revenueExpenses: boolean
    revenueByAccount: boolean
    saleOfServices: boolean
    saleOfCards: boolean
    saleOfProducts: boolean
    leadsOfClients: boolean
    clientsVisits: boolean
    incomingPayments: boolean
    outgoingPayments: boolean
  }
  review: { get: boolean; set: boolean }
  lead: { set: boolean }
  tag: { set: boolean }
  revenueReason: { set: boolean }
  expensesReason: { set: boolean }
  movement: { get: boolean; set: boolean }
  transaction: {
    get: boolean
    set: boolean
    editPast: boolean
    betweenAccounts: boolean
    setRefund: boolean
  }
  timeOff: { get: boolean; set: boolean }
  timeOffReason: { set: boolean }
  location: { get: boolean; set: boolean; archive: boolean }
  analytics: { get: boolean }
  notification: { get: boolean; billing: boolean }
}

export interface PermissionPageFormData {
  roleId: string
  permissions: PermissionsInFormData
}

export interface CheckRelationProps {
  isSelect: boolean
  onConfirm: () => void
  permission: PermissionName
}

export interface PermissionPageRowProps {
  control: Control<PermissionPageFormData>
  checkRelation: (props: CheckRelationProps) => void
  isItOwnerRole: boolean
}

export interface Relation {
  name: PermissionName
  selected: boolean
  dependsOn: PermissionName[]
}

const getPermissionsListByRoleId = (roleId: string, rolePermissions: ServerRolePermissionType[]) =>
  rolePermissions.filter(permission => permission.roleId === roleId)

export const transformPermissionsIntoFormData = ({
  roleId,
  rolePermissions,
}: {
  roleId: string
  rolePermissions: ServerRolePermissionType[]
}): PermissionsInFormData => {
  const permissionList = getPermissionsListByRoleId(roleId, rolePermissions)

  const result: PermissionsInFormData = {
    client: { get: false, set: false, phone: false, archive: false },
    clientNote: { get: false, set: false },
    clientMessage: { get: false, set: false },
    employee: {
      get: false,
      set: false,
      email: false,
      archive: false,
    },
    booking: {
      get: false,
      set: false,
      setOwn: false,
      editPast: false,
    },
    bookingStatus: {
      set: false,
      unset: false,
    },
    business: { set: false },
    billingInfo: { get: false, set: false },
    branch: { set: false, getAll: false },
    service: {
      get: false,
      set: false,
      archive: false,
    },
    account: { get: false, set: false },
    storage: { get: false, set: false },
    salary: { get: false, set: false },
    rolePermission: { set: false },
    inventory: { get: false, set: false },
    product: { get: false, set: false, archive: false },
    schedule: { get: false, set: false, editPast: false },
    supplier: { get: false, set: false },
    card: { get: false, set: false },
    waitingList: { get: false, set: false },
    report: {
      revenueExpenses: false,
      revenueByAccount: false,
      saleOfServices: false,
      saleOfCards: false,
      saleOfProducts: false,
      leadsOfClients: false,
      clientsVisits: false,
      incomingPayments: false,
      outgoingPayments: false,
    },
    review: { get: false, set: false },
    lead: { set: false },
    tag: { set: false },
    revenueReason: { set: false },
    expensesReason: { set: false },
    movement: { get: false, set: false },
    transaction: {
      get: false,
      set: false,
      editPast: false,
      betweenAccounts: false,
      setRefund: false,
    },
    timeOff: { get: false, set: false },
    timeOffReason: { set: false },
    location: { get: false, set: false, archive: false },
    analytics: { get: false },
    notification: { get: false, billing: false },
  }

  for (const { permissionId } of permissionList) {
    const [key, permission] = permissionId.split('.')

    if (Object.prototype.hasOwnProperty.call(result, key)) result[key][permission] = true
  }

  return result
}

type PermissionNameInForm<Permission extends keyof PermissionsInFormData> =
  `permissions.${keyof Pick<PermissionsInFormData, Permission>}.${Extract<
    keyof PermissionsInFormData[Permission],
    string
  >}`

export type PermissionName =
  | PermissionNameInForm<'client'>
  | PermissionNameInForm<'clientNote'>
  | PermissionNameInForm<'clientMessage'>
  | PermissionNameInForm<'business'>
  | PermissionNameInForm<'billingInfo'>
  | PermissionNameInForm<'branch'>
  | PermissionNameInForm<'employee'>
  | PermissionNameInForm<'booking'>
  | PermissionNameInForm<'bookingStatus'>
  | PermissionNameInForm<'service'>
  | PermissionNameInForm<'account'>
  | PermissionNameInForm<'storage'>
  | PermissionNameInForm<'salary'>
  | PermissionNameInForm<'rolePermission'>
  | PermissionNameInForm<'inventory'>
  | PermissionNameInForm<'product'>
  | PermissionNameInForm<'schedule'>
  | PermissionNameInForm<'supplier'>
  | PermissionNameInForm<'card'>
  | PermissionNameInForm<'waitingList'>
  | PermissionNameInForm<'report'>
  | PermissionNameInForm<'review'>
  | PermissionNameInForm<'lead'>
  | PermissionNameInForm<'tag'>
  | PermissionNameInForm<'revenueReason'>
  | PermissionNameInForm<'expensesReason'>
  | PermissionNameInForm<'movement'>
  | PermissionNameInForm<'transaction'>
  | PermissionNameInForm<'timeOff'>
  | PermissionNameInForm<'timeOffReason'>
  | PermissionNameInForm<'location'>
  | PermissionNameInForm<'analytics'>
  | PermissionNameInForm<'notification'>

export const transformPermissionsForServer = (
  data: PermissionPageFormData,
  isItMyRole: boolean,
): ServerRolePermissionInsertInput[] => {
  const result: ServerRolePermissionInsertInput[] = []

  const roleId = data.roleId

  for (const [permissionName, permissionValue] of Object.entries(data.permissions)) {
    // We don't wanna insert new permission because we are not removing it
    if (isItMyRole && permissionName === 'rolePermission') {
      continue
    }
    for (const [action, value] of Object.entries(permissionValue)) {
      if (value) result.push({ roleId, permissionId: `${permissionName}.${action}` })
    }
  }

  return result
}
