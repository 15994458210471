import { gql } from 'graphql-request'
import { queryClient, reportGqlError, request, requestAnonymously, useMutation } from '../../api'
import {
  ServerBookingClientInsertInput,
  ServerBookingClientMutationResponse,
  ServerBookingInsertInput,
  ServerBookingProductInsertInput,
  ServerBookingProductMutationResponse,
  ServerBookingServiceInsertInput,
  ServerBookingServiceMutationResponse,
  ServerBookingSetInput,
  ServerMutationRootCreateBookingArgs,
  ServerTransactionInsertInput,
} from '../../generated/graphql-types'
import { TRANSACTION_SERVICES_QUERY_KEY, TRANSACTIONS_QUERY_KEY } from '../transaction/queryKeys'
import { CARDS_QUERY_KEY } from '../card/queryKeys'
import { MOVEMENTS_QUERY_KEY } from '../movement/queryKeys'
import { CLIENTS_AMOUNT_QUERY_KEY, CLIENTS_QUERY_KEY } from '../client/queryKeys'
import { invalidateBookings } from './logic'
import {
  BOOKING_AS_CLIENT_QUERY_KEY,
  BOOKING_HISTORY_QUERY_KEY,
  BOOKINGS_BY_IDS_QUERY_KEY,
  BOOKINGS_QUERY_KEY,
} from './queryKeys'
import { UNITED_BOOKING_QUERY_KEY } from '../unitedBooking/queryKeys'
import { SALARY_ISSUES_QUERY_KEY } from '../salaryIssue/queryKeys'
import { endOfWeek, startOfWeek } from '@expane/date'
import { PRODUCTS_LEFTOVERS_QUERY_KEYS } from '../product/queryKeys'
import { CloudFunctionResult } from '../responses'

export type BookingInsertInput = ServerBookingInsertInput &
  Required<Pick<ServerBookingInsertInput, 'branchId'>>

export function useCreateBooking(options?: {
  invalidateWeek?: boolean
  customOnSuccessFunc?: () => void
}) {
  return useMutation(
    async (
      bookingInsertInput: BookingInsertInput,
    ): Promise<{ insertBooking: { id: number } } | undefined> => {
      return request(
        gql`
          mutation ($bookingInsertInput: booking_insert_input!) {
            insertBooking(object: $bookingInsertInput) {
              id
            }
          }
        `,
        {
          bookingInsertInput,
        },
      )
    },
    {
      onSuccess: (response, data) => {
        invalidateBookings({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          date: data.startDate!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          branchId: data.branchId!,
          dateRange: options?.invalidateWeek
            ? [
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                startOfWeek(data.startDate!, { weekStartsOn: 1 }),
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                endOfWeek(data.startDate!, { weekStartsOn: 1 }),
              ]
            : undefined,
        })
        if (data.client?.data.firstName) {
          // если был создан клиент
          queryClient.invalidateQueries([CLIENTS_QUERY_KEY])
          queryClient.setQueryData([CLIENTS_AMOUNT_QUERY_KEY], (prev: number | undefined) =>
            prev ? prev + 1 : 1,
          )
        }
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(BOOKING_HISTORY_QUERY_KEY),
        })
        options?.customOnSuccessFunc?.()
      },
      onError: reportGqlError,
    },
  )
}

export function useCreateBookings(options?: { invalidateWeek?: boolean }) {
  return useMutation(
    async (
      bookingsInsertInput: BookingInsertInput[],
    ): Promise<{
      insertBookings:
        | { affectedRows: number[]; returning: Array<{ id: number }> | undefined }
        | undefined
    }> => {
      const dto = { bookingsInsertInput }
      return request(
        gql`
          mutation ($bookingsInsertInput: [booking_insert_input!] = {}) {
            insertBookings(objects: $bookingsInsertInput) {
              returning {
                id
              }
              affectedRows: affected_rows
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: (response, data) => {
        data.forEach(booking =>
          invalidateBookings({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            date: booking.startDate!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            branchId: booking.branchId!,
            dateRange: options?.invalidateWeek
              ? [
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  startOfWeek(booking.startDate!, { weekStartsOn: 1 }),
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  endOfWeek(booking.startDate!, { weekStartsOn: 1 }),
                ]
              : undefined,
          }),
        )
      },
      onError: reportGqlError,
    },
  )
}

export type UpdateBookingType = {
  id: number
  bookingSetInput: ServerBookingSetInput
  oldStartDate: Date | undefined
  bookingServiceInsertInput?: ServerBookingServiceInsertInput[]
  bookingServicesToBeRemovedIds?: number[]
  bookingClientInsertInput?: ServerBookingClientInsertInput[]
  bookingClientsToBeRemovedIds?: number[]
  bookingProductInsertInput?: ServerBookingProductInsertInput[]
  bookingProductsToBeRemovedIds?: number[]
  // for invalidation
  branchId: number
}

export function useUpdateBooking() {
  return useMutation(
    async (
      dto: UpdateBookingType,
    ): Promise<{
      updateBookingById: { id: number }
      deleteBookingServices?: ServerBookingServiceMutationResponse
      insertBookingServices?: ServerBookingServiceMutationResponse
      deleteBookingClients?: ServerBookingClientMutationResponse
      insertBookingClients?: ServerBookingClientMutationResponse
      deleteBookingProducts?: ServerBookingProductMutationResponse
      insertBookingProducts?: ServerBookingProductMutationResponse
    }> => {
      const {
        id,
        bookingSetInput,
        bookingServiceInsertInput,
        bookingServicesToBeRemovedIds,
        bookingClientInsertInput,
        bookingClientsToBeRemovedIds,
        bookingProductInsertInput,
        bookingProductsToBeRemovedIds,
      } = dto

      return request(
        gql`
          mutation (
            $id: Int!
            $bookingSetInput: booking_set_input!
            # Services
            $bookingServiceInsertInput: [bookingService_insert_input!]!
            $includeBookingServices: Boolean!
            $bookingServicesToBeRemovedIds: [Int!]
            $includeDeletingBookingServices: Boolean!
            # Clients
            $bookingClientInsertInput: [bookingClient_insert_input!]!
            $includeBookingClient: Boolean!
            $bookingClientsToBeRemovedIds: [Int!]
            $includeDeletingBookingClients: Boolean!
            # Products
            $bookingProductInsertInput: [bookingProduct_insert_input!]!
            $includeBookingProduct: Boolean!
            $bookingProductsToBeRemovedIds: [Int!]
            $includeDeletingBookingProducts: Boolean!
          ) {
            deleteBookingServices(where: { id: { _in: $bookingServicesToBeRemovedIds } })
              @include(if: $includeDeletingBookingServices) {
              affected_rows
            }
            insertBookingServices(objects: $bookingServiceInsertInput)
              @include(if: $includeBookingServices) {
              affected_rows
            }
            deleteBookingClients(where: { id: { _in: $bookingClientsToBeRemovedIds } })
              @include(if: $includeDeletingBookingClients) {
              affected_rows
            }
            insertBookingClients(
              objects: $bookingClientInsertInput
              on_conflict: { constraint: unique_booking_client_pair, update_columns: [] }
            ) @include(if: $includeBookingClient) {
              affected_rows
            }
            deleteBookingProducts(where: { id: { _in: $bookingProductsToBeRemovedIds } })
              @include(if: $includeDeletingBookingProducts) {
              affected_rows
            }
            insertBookingProducts(
              objects: $bookingProductInsertInput
              on_conflict: { update_columns: quantity, constraint: bookingProduct_pkey }
            ) @include(if: $includeBookingProduct) {
              affected_rows
            }
            updateBookingById(pk_columns: { id: $id }, _set: $bookingSetInput) {
              id
            }
          }
        `,
        {
          id,
          bookingSetInput,
          // Services
          bookingServiceInsertInput: bookingServiceInsertInput ?? [],
          includeBookingServices: Boolean(bookingServiceInsertInput),
          bookingServicesToBeRemovedIds,
          includeDeletingBookingServices: Boolean(bookingServicesToBeRemovedIds?.length),
          // Clients
          bookingClientInsertInput: bookingClientInsertInput ?? [],
          includeBookingClient: Boolean(bookingClientInsertInput),
          bookingClientsToBeRemovedIds,
          includeDeletingBookingClients: Boolean(bookingClientsToBeRemovedIds?.length),
          // Products
          bookingProductInsertInput: bookingProductInsertInput ?? [],
          includeBookingProduct: Boolean(bookingProductInsertInput),
          bookingProductsToBeRemovedIds,
          includeDeletingBookingProducts: Boolean(bookingProductsToBeRemovedIds?.length),
        },
      )
    },
    {
      onSuccess: (response, data) => {
        invalidateBookings({
          id: data.id,
          date: data.bookingSetInput.startDate ?? undefined,
          oldDate: data.oldStartDate,
          branchId: data.branchId,
        })
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(BOOKING_HISTORY_QUERY_KEY),
        })
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(BOOKING_AS_CLIENT_QUERY_KEY),
        })
        // возможно могут возникать проблемы с производительностью
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(BOOKINGS_BY_IDS_QUERY_KEY),
        })
      },
      onError: reportGqlError,
    },
  )
}

export function useBookingPaymentMutation() {
  return useMutation(
    async (dto: { transactions: ServerTransactionInsertInput[]; subscriptionIds: number[] }) => {
      await request(
        gql`
          mutation ($transactions: [transaction_insert_input!]!, $subscriptionIds: [Int!]!) {
            insertTransactions(objects: $transactions) {
              affected_rows
            }
            updateCards(where: { id: { _in: $subscriptionIds } }, _set: { activatedAt: "now()" }) {
              affected_rows
            }
          }
        `,
        dto,
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(TRANSACTIONS_QUERY_KEY),
        })
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(CARDS_QUERY_KEY),
        })
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(PRODUCTS_LEFTOVERS_QUERY_KEYS),
        })
        // creating consumable movements
        queryClient.invalidateQueries([MOVEMENTS_QUERY_KEY])
        // activating subscriptions
        queryClient.invalidateQueries([CLIENTS_QUERY_KEY])
        queryClient.invalidateQueries([CARDS_QUERY_KEY])
      },
    },
  )
}

export function useCancelBookings() {
  return useMutation(
    async (dto: {
      ids: number[]
      canceledReason: string | null
    }): Promise<{ updateBookings: { affectedRows: number } }> => {
      return request(
        gql`
          mutation ($ids: [Int!]!, $canceledDate: timestamptz!, $canceledReason: String) {
            updateBookings(
              where: { id: { _in: $ids } }
              _set: { canceledDate: $canceledDate, canceledReason: $canceledReason }
            ) {
              affectedRows: affected_rows
            }
          }
        `,
        { ...dto, canceledDate: new Date() },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(UNITED_BOOKING_QUERY_KEY),
        })
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(BOOKINGS_QUERY_KEY),
        })
      },
    },
  )
}

export function useCancelBooking() {
  return useMutation(
    async (dto: {
      id: number
      canceledReason: string | null
    }): Promise<{ updateBookingById: { id: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $canceledReason: String, $canceledDate: timestamptz) {
            updateBookingById(
              pk_columns: { id: $id }
              _set: { canceledDate: $canceledDate, canceledReason: $canceledReason }
            ) {
              id
            }
          }
        `,
        { ...dto, canceledDate: new Date() },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([BOOKINGS_QUERY_KEY])
        queryClient.invalidateQueries([UNITED_BOOKING_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export function useUpdateBookingStatus() {
  return useMutation(
    async (dto: {
      id: number
      status: number
    }): Promise<{ updateBookingStatuses: { affectedRows: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $status: smallint!) {
            updateBookingStatuses(where: { id: { _eq: $id } }, _set: { status: $status }) {
              affectedRows: affected_rows
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([BOOKINGS_QUERY_KEY])
        queryClient.invalidateQueries([TRANSACTION_SERVICES_QUERY_KEY])
        queryClient.invalidateQueries([SALARY_ISSUES_QUERY_KEY])
        queryClient.invalidateQueries([UNITED_BOOKING_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export function useUpdateBookingsStatuses() {
  return useMutation(
    async (dto: {
      ids: number[]
      status: number
    }): Promise<{ updateBookingStatuses: { affectedRows: number } }> => {
      return request(
        gql`
          mutation ($ids: [Int!]!, $status: smallint!) {
            updateBookingStatuses(where: { id: { _in: $ids } }, _set: { status: $status }) {
              affectedRows: affected_rows
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([BOOKINGS_QUERY_KEY])
        queryClient.invalidateQueries([TRANSACTION_SERVICES_QUERY_KEY])
        queryClient.invalidateQueries([UNITED_BOOKING_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

// --- AS CLIENT ---

type CreateBookingDtoCommon = Pick<
  ServerMutationRootCreateBookingArgs,
  'locationId' | 'employeeId' | 'serviceIds' | 'branchId' | 'clientNote'
>
type CreateBookingDtoOne = CreateBookingDtoCommon & {
  date: Date
  dates: null
}
type CreateBookingDtoSeveral = CreateBookingDtoCommon & {
  date: null
  dates: Date[]
}
type CreateBookingDto = CreateBookingDtoOne | CreateBookingDtoSeveral

// use `date` if one booking, `dates` if several bookings
export function useCreateBookingAsClientGCF() {
  return useMutation(
    (insertInput: CreateBookingDto) =>
      request<{ createBooking: { success: boolean } }>(
        gql`
          mutation MyMutation(
            $date: timestamptz
            $dates: [timestamptz!]
            $employeeId: Int
            $locationId: Int!
            $serviceIds: [Int!]!
            $branchId: Int!
            $clientNote: String
          ) {
            createBooking(
              date: $date
              dates: $dates
              locationId: $locationId
              serviceIds: $serviceIds
              employeeId: $employeeId
              branchId: $branchId
              clientNote: $clientNote
            ) {
              success
            }
          }
        `,
        insertInput,
      ),
    {
      onSuccess: () => queryClient.invalidateQueries([BOOKINGS_QUERY_KEY]),
      onError: reportGqlError,
    },
  )
}

// используется когда одного клиента нужно добавить в разные букинги
export function useUpdateBookingsClientsAsClient() {
  return useMutation(
    async (
      bookingIds: Array<number>,
    ): Promise<{
      insertBookingClients: { affectedRows: number }
    }> => {
      return request(
        gql`
          mutation ($bookings: [bookingClient_insert_input!]!) {
            insertBookingClients(objects: $bookings) {
              affectedRows: affected_rows
            }
          }
        `,
        {
          bookings: bookingIds.map(id => ({ bookingId: id })),
        },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([BOOKINGS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export function useDeleteMyClientFromBooking(myClientId: number | null) {
  return useMutation(
    async (dto: { id: number }): Promise<{ deleteBookingClients: { affectedRows: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $clientToBeRemovedId: Int!) {
            deleteBookingClients(
              where: { bookingId: { _eq: $id }, clientId: { _eq: $clientToBeRemovedId } }
            ) {
              affectedRows: affected_rows
            }
          }
        `,
        {
          id: dto.id,
          clientToBeRemovedId: myClientId,
        },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(BOOKINGS_QUERY_KEY),
        })
      },
      onError: reportGqlError,
    },
  )
}

type CreateAnonymouslyBookingDto = {
  businessId: number
  clientFirstName: string
  clientLastName: string
  clientPhone: string
  clientEmail: string | undefined
  date: Date
  employeeId: number | null
  locationId: number
  serviceIds: number[]
  branchId: number
  // i18n lang
  clientLang: string
  clientNote?: string
}

export function useCreateAnonymouslyBooking() {
  return useMutation(
    (dto: CreateAnonymouslyBookingDto): Promise<{ anonCreateBooking?: CloudFunctionResult }> => {
      return requestAnonymously(
        gql`
          mutation (
            $businessId: Int!
            $clientFirstName: String!
            $clientLastName: String!
            $date: timestamptz!
            $employeeId: Int
            $locationId: Int!
            $serviceIds: [Int!]!
            $clientPhone: String!
            $clientEmail: String
            $branchId: Int!
            $clientLang: Languages!
            $clientNote: String
          ) {
            anonCreateBooking(
              businessId: $businessId
              clientFirstName: $clientFirstName
              clientLastName: $clientLastName
              date: $date
              locationId: $locationId
              employeeId: $employeeId
              serviceIds: $serviceIds
              clientPhone: $clientPhone
              clientEmail: $clientEmail
              clientLang: $clientLang
              branchId: $branchId
              clientNote: $clientNote
            ) {
              message
              code
            }
          }
        `,
        dto,
      )
    },
    {
      onError: reportGqlError,
    },
  )
}
