import { TFunction } from 'react-i18next'
import { ServerClientBriefType } from '@expane/data'

export const validateClientEmail = (
  email: string | undefined,
  clients: ServerClientBriefType[] | undefined,
  clientIdToExclude: number | undefined,
) => {
  if (!email) return true
  if (!clients) return false

  const clientWithThisEmail = clients.find(client => client.email === email)
  if (!clientWithThisEmail) return true

  return clientWithThisEmail.id === clientIdToExclude
}

export const generateEmailErrorMessage = (
  type: string | undefined,
  t: TFunction,
): string | undefined => {
  if (type === 'validateEmail') return t('authForm.wrongEmail')
  if (type === 'validateClientEmail') return t('alreadyInUse')
}
