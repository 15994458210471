import { ChangeEvent, forwardRef, InputHTMLAttributes, useLayoutEffect, useRef } from 'react'
import { IconType } from 'react-icons'
import { mergeRefs } from 'react-merge-refs'
import { getCommonInputStyles } from '../../logic'
import { ErrorMessage, ErrorMessageType } from '../ErrorMessage'
import { InputLabel } from '../InputLabel'

export type InputHeight = 'small' | 'medium' | 'normal'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  hint?: string
  Icon?: IconType
  containerClassName?: string
  errorMessage?: ErrorMessageType
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  height?: InputHeight
}

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    label,
    hint,
    containerClassName,
    errorMessage,
    disabled,
    onChange,
    Icon,
    height = 'normal',
    className,
    ...restProps
  } = props

  const innerRef = useRef<HTMLInputElement>(null)
  const mergedRef = mergeRefs([ref, innerRef])

  let inputClassName =
    getCommonInputStyles(errorMessage?.isShown, disabled) +
    'block w-full border-2 rounded-lg px-3 text-sm focus:ring-0 transition-all '

  if (height === 'normal') inputClassName += 'h-9.5 '
  if (height === 'medium') inputClassName += 'h-8 '
  if (height === 'small') inputClassName += 'h-7 '

  inputClassName += Icon ? ' pl-7 capitalize' : ''

  useLayoutEffect(() => {
    const innerRefCurrent = innerRef.current

    if (!innerRefCurrent) return

    const preventDefault = (e: WheelEvent) => e.preventDefault()

    innerRefCurrent.addEventListener('wheel', preventDefault)

    return () => {
      innerRefCurrent.removeEventListener('wheel', preventDefault)
    }
  }, [])

  // в компонентах где есть sticky мешает position relative. TODO: Нужно найти решение получше
  return (
    <div className={containerClassName}>
      <InputLabel label={label} hint={hint} required={restProps.required} />

      <div className={`${Icon ? 'relative' : ''} w-full`}>
        {Icon ? (
          <Icon size="1rem" className="absolute text-icon-color left-2 top-2/4 -translate-y-2/4" />
        ) : null}

        <input
          ref={mergedRef}
          name="price"
          className={className ? inputClassName + ` ${className}` : inputClassName}
          disabled={disabled}
          onChange={onChange}
          autoComplete="off"
          {...restProps}
        />
      </div>
      <ErrorMessage errorMessage={errorMessage} />
    </div>
  )
})
