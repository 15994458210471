import {
  CheckRelationProps,
  PermissionName,
  PermissionPageFormData,
} from '@expane/logic/permission/logic'
import { Checkbox, useShowConfirmationPopup } from '@expane/ui'
import { useMemo } from 'react'
import { Control, UseFormSetValue, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CheckRelationBulkProps, Relation } from './logic'

// TODO: https://github.com/expane-pro/expane-admin/issues/11336
export const useRelationsWithConfirmation = (
  control: Control<PermissionPageFormData>,
  setValue: UseFormSetValue<PermissionPageFormData>,
) => {
  const { t } = useTranslation()
  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

  const watchedPermissions = useWatch({ control, name: 'permissions' })

  const relations = useMemo<Relation[]>(
    () => [
      {
        name: 'permissions.client.get',
        selected: watchedPermissions.client.get,
        dependsOn: [],
      },
      {
        name: 'permissions.client.set',
        selected: watchedPermissions.client.set,
        dependsOn: ['permissions.client.get', 'permissions.client.phone'],
      },
      {
        name: 'permissions.client.phone',
        selected: watchedPermissions.client.phone,
        dependsOn: [],
      },
      {
        name: 'permissions.clientNote.get',
        selected: watchedPermissions.clientNote.get,
        dependsOn: [
          'permissions.client.get',
          'permissions.employee.get',
          'permissions.service.get',
        ],
      },
      {
        name: 'permissions.clientNote.set',
        selected: watchedPermissions.clientNote.set,
        dependsOn: [
          'permissions.clientNote.get',
          'permissions.client.get',
          'permissions.employee.get',
          'permissions.service.get',
        ],
      },
      {
        name: 'permissions.clientMessage.get',
        selected: watchedPermissions.clientMessage.get,
        dependsOn: [],
      },
      {
        name: 'permissions.clientMessage.set',
        selected: watchedPermissions.clientMessage.set,
        dependsOn: [],
      },
      {
        name: 'permissions.business.set',
        selected: watchedPermissions.business.set,
        dependsOn: [],
      },
      {
        name: 'permissions.billingInfo.get',
        selected: watchedPermissions.billingInfo.get,
        dependsOn: [],
      },
      {
        name: 'permissions.billingInfo.set',
        selected: watchedPermissions.billingInfo.set,
        dependsOn: ['permissions.billingInfo.get'],
      },
      { name: 'permissions.branch.set', selected: watchedPermissions.branch.set, dependsOn: [] },
      {
        name: 'permissions.employee.get',
        selected: watchedPermissions.employee.get,
        dependsOn: ['permissions.service.get', 'permissions.location.get'],
      },
      {
        name: 'permissions.employee.set',
        selected: watchedPermissions.employee.set,
        dependsOn: [
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
        ],
      },
      {
        name: 'permissions.employee.archive',
        selected: watchedPermissions.employee.archive,
        dependsOn: [
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
        ],
      },
      {
        name: 'permissions.employee.email',
        selected: watchedPermissions.employee.email,
        dependsOn: [
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
        ],
      },
      {
        name: 'permissions.booking.get',
        selected: watchedPermissions.booking.get,
        dependsOn: [
          'permissions.client.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
          'permissions.schedule.get',
          'permissions.transaction.get',
          'permissions.account.get',
        ],
      },
      {
        name: 'permissions.booking.set',
        selected: watchedPermissions.booking.set,
        dependsOn: [
          'permissions.booking.get',
          'permissions.client.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
          'permissions.schedule.get',
          'permissions.transaction.get',
          'permissions.account.get',
        ],
      },
      {
        name: 'permissions.booking.setOwn',
        selected: watchedPermissions.booking.setOwn,
        dependsOn: [], // TODO: перевірити як буде працювати
      },
      {
        name: 'permissions.booking.editPast',
        selected: watchedPermissions.booking.editPast,
        dependsOn: [
          'permissions.booking.set',
          'permissions.booking.get',
          'permissions.client.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
          'permissions.schedule.get',
          'permissions.transaction.get',
          'permissions.account.get',
        ],
      },
      {
        name: 'permissions.bookingStatus.set',
        selected: watchedPermissions.bookingStatus.set,
        dependsOn: [], // Залежить від багатьох, але також може бути змінений за доступом booking.setOwn
        // тому треба перевірити як буде працювати
      },
      {
        name: 'permissions.bookingStatus.unset',
        selected: watchedPermissions.bookingStatus.unset,
        dependsOn: ['permissions.bookingStatus.set'],
      },
      {
        name: 'permissions.service.get',
        selected: watchedPermissions.service.get,
        dependsOn: ['permissions.employee.get', 'permissions.location.get'],
      },
      {
        name: 'permissions.service.set',
        selected: watchedPermissions.service.set,
        dependsOn: [
          'permissions.service.get',
          'permissions.employee.get',
          'permissions.location.get',
        ],
      },
      {
        name: 'permissions.service.archive',
        selected: watchedPermissions.service.archive,
        dependsOn: [
          'permissions.service.get',
          'permissions.service.set',
          'permissions.employee.get',
          'permissions.location.get',
        ],
      },
      { name: 'permissions.account.get', selected: watchedPermissions.account.get, dependsOn: [] },
      {
        name: 'permissions.account.set',
        selected: watchedPermissions.account.set,
        dependsOn: ['permissions.account.get'],
      },
      {
        name: 'permissions.storage.get',
        selected: watchedPermissions.storage.get,
        dependsOn: [
          'permissions.movement.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
          'permissions.product.get',
          'permissions.transaction.get',
          'permissions.booking.get',
          'permissions.client.get',
          'permissions.schedule.get',
          'permissions.account.get',
        ],
      },
      {
        name: 'permissions.storage.set',
        selected: watchedPermissions.storage.set,
        dependsOn: [
          'permissions.storage.get',
          'permissions.movement.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
          'permissions.product.get',
          'permissions.transaction.get',
          'permissions.booking.get',
          'permissions.client.get',
          'permissions.schedule.get',
          'permissions.account.get',
        ],
      },
      {
        name: 'permissions.salary.get',
        selected: watchedPermissions.salary.get,
        dependsOn: [
          'permissions.booking.get',
          'permissions.client.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
          'permissions.schedule.get',
          'permissions.transaction.get',
          'permissions.account.get',
        ],
      },
      {
        name: 'permissions.salary.set',
        selected: watchedPermissions.salary.set,
        dependsOn: [
          'permissions.salary.get',
          'permissions.booking.get',
          'permissions.client.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
          'permissions.schedule.get',
          'permissions.transaction.get',
          'permissions.transaction.set',
          'permissions.account.get',
        ],
      },
      {
        name: 'permissions.rolePermission.set',
        selected: watchedPermissions.rolePermission.set,
        dependsOn: [],
      },
      {
        name: 'permissions.inventory.get',
        selected: watchedPermissions.inventory.get,
        dependsOn: [
          'permissions.storage.get',
          'permissions.movement.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
          'permissions.product.get',
          'permissions.transaction.get',
          'permissions.booking.get',
          'permissions.client.get',
          'permissions.schedule.get',
          'permissions.account.get',
        ],
      },
      {
        name: 'permissions.inventory.set',
        selected: watchedPermissions.inventory.set,
        dependsOn: [
          'permissions.inventory.get',
          'permissions.storage.get',
          'permissions.movement.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
          'permissions.product.get',
          'permissions.transaction.get',
          'permissions.booking.get',
          'permissions.client.get',
          'permissions.schedule.get',
          'permissions.account.get',
        ],
      },
      {
        name: 'permissions.product.get',
        selected: watchedPermissions.product.get,
        dependsOn: [],
      },
      {
        name: 'permissions.product.set',
        selected: watchedPermissions.product.set,
        dependsOn: ['permissions.product.get'],
      },
      {
        name: 'permissions.product.archive',
        selected: watchedPermissions.product.archive,
        dependsOn: ['permissions.product.get', 'permissions.product.set'],
      },
      {
        name: 'permissions.schedule.get',
        selected: watchedPermissions.schedule.get,
        dependsOn: [],
      },
      {
        name: 'permissions.schedule.set',
        selected: watchedPermissions.schedule.set,
        dependsOn: ['permissions.schedule.get'],
      },
      {
        name: 'permissions.supplier.get',
        selected: watchedPermissions.supplier.get,
        dependsOn: [],
      },
      {
        name: 'permissions.supplier.set',
        selected: watchedPermissions.supplier.set,
        dependsOn: ['permissions.supplier.get'],
      },
      {
        name: 'permissions.card.get',
        selected: watchedPermissions.card.get,
        dependsOn: [
          'permissions.service.get',
          'permissions.employee.get',
          'permissions.location.get',
        ],
      },
      {
        name: 'permissions.card.set',
        selected: watchedPermissions.card.set,
        dependsOn: [
          'permissions.card.get',
          'permissions.service.get',
          'permissions.employee.get',
          'permissions.location.get',
        ],
      },
      {
        name: 'permissions.waitingList.get',
        selected: watchedPermissions.waitingList.get,
        dependsOn: [
          'permissions.client.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
        ],
      },
      {
        name: 'permissions.waitingList.set',
        selected: watchedPermissions.waitingList.set,
        dependsOn: [
          'permissions.waitingList.get',
          'permissions.client.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
        ],
      },
      { name: 'permissions.lead.set', selected: watchedPermissions.lead.set, dependsOn: [] },
      { name: 'permissions.tag.set', selected: watchedPermissions.tag.set, dependsOn: [] },
      {
        name: 'permissions.revenueReason.set',
        selected: watchedPermissions.revenueReason.set,
        dependsOn: [],
      },
      {
        name: 'permissions.expensesReason.set',
        selected: watchedPermissions.expensesReason.set,
        dependsOn: [],
      },
      {
        name: 'permissions.movement.get',
        selected: watchedPermissions.movement.get,
        dependsOn: [
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
          'permissions.product.get',
          'permissions.transaction.get',
          'permissions.booking.get',
          'permissions.client.get',
          'permissions.schedule.get',
          'permissions.account.get',
        ],
      },
      {
        name: 'permissions.movement.set',
        selected: watchedPermissions.movement.set,
        dependsOn: [
          'permissions.movement.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
          'permissions.product.get',
          'permissions.transaction.get',
          'permissions.booking.get',
          'permissions.client.get',
          'permissions.schedule.get',
          'permissions.account.get',
        ],
      },
      {
        name: 'permissions.transaction.get',
        selected: watchedPermissions.transaction.get,
        dependsOn: [
          'permissions.booking.get',
          'permissions.client.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
          'permissions.schedule.get',
          'permissions.account.get',
        ],
      },
      {
        name: 'permissions.transaction.set',
        selected: watchedPermissions.transaction.set,
        dependsOn: [
          'permissions.transaction.get',
          'permissions.booking.get',
          'permissions.client.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
          'permissions.schedule.get',
          'permissions.account.get',
        ],
      },
      {
        name: 'permissions.transaction.betweenAccounts',
        selected: watchedPermissions.transaction.betweenAccounts,
        dependsOn: [
          'permissions.transaction.get',
          'permissions.transaction.set',
          'permissions.booking.get',
          'permissions.client.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
          'permissions.schedule.get',
          'permissions.account.get',
        ],
      },
      {
        name: 'permissions.transaction.editPast',
        selected: watchedPermissions.transaction.editPast,
        dependsOn: [
          'permissions.transaction.get',
          'permissions.transaction.set',
          'permissions.booking.get',
          'permissions.client.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
          'permissions.schedule.get',
          'permissions.account.get',
        ],
      },
      {
        name: 'permissions.transaction.setRefund',
        selected: watchedPermissions.transaction.setRefund,
        dependsOn: [
          'permissions.transaction.get',
          'permissions.transaction.set',
          'permissions.booking.get',
          'permissions.client.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
          'permissions.schedule.get',
          'permissions.account.get',
        ],
      },
      { name: 'permissions.timeOff.get', selected: watchedPermissions.timeOff.get, dependsOn: [] },
      {
        name: 'permissions.timeOff.set',
        selected: watchedPermissions.timeOff.set,
        dependsOn: ['permissions.timeOff.get'],
      },
      {
        name: 'permissions.timeOffReason.set',
        selected: watchedPermissions.timeOffReason.set,
        dependsOn: ['permissions.timeOff.get'],
      },
      {
        name: 'permissions.location.get',
        selected: watchedPermissions.location.get,
        dependsOn: [],
      },
      {
        name: 'permissions.location.set',
        selected: watchedPermissions.location.set,
        dependsOn: ['permissions.location.get'],
      },
      {
        name: 'permissions.location.archive',
        selected: watchedPermissions.location.archive,
        dependsOn: ['permissions.location.get'],
      },
      // analytics
      {
        name: 'permissions.analytics.get',
        selected: watchedPermissions.analytics.get,
        dependsOn: [
          'permissions.movement.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
          'permissions.product.get',
          'permissions.transaction.get',
          'permissions.booking.get',
          'permissions.client.get',
          'permissions.schedule.get',
          'permissions.account.get',
        ],
      },
      {
        name: 'permissions.report.clientsVisits',
        selected: watchedPermissions.report.clientsVisits,
        dependsOn: [
          'permissions.analytics.get',
          'permissions.movement.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
          'permissions.product.get',
          'permissions.transaction.get',
          'permissions.booking.get',
          'permissions.client.get',
          'permissions.schedule.get',
          'permissions.account.get',
        ],
      },
      {
        name: 'permissions.report.leadsOfClients',
        selected: watchedPermissions.report.leadsOfClients,
        dependsOn: [
          'permissions.analytics.get',
          'permissions.movement.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
          'permissions.product.get',
          'permissions.transaction.get',
          'permissions.booking.get',
          'permissions.client.get',
          'permissions.schedule.get',
          'permissions.account.get',
        ],
      },
      {
        name: 'permissions.report.incomingPayments',
        selected: watchedPermissions.report.incomingPayments,
        dependsOn: [
          'permissions.analytics.get',
          'permissions.movement.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
          'permissions.product.get',
          'permissions.transaction.get',
          'permissions.booking.get',
          'permissions.client.get',
          'permissions.schedule.get',
          'permissions.account.get',
        ],
      },
      {
        name: 'permissions.report.outgoingPayments',
        selected: watchedPermissions.report.outgoingPayments,
        dependsOn: [
          'permissions.analytics.get',
          'permissions.movement.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
          'permissions.product.get',
          'permissions.transaction.get',
          'permissions.booking.get',
          'permissions.client.get',
          'permissions.schedule.get',
          'permissions.account.get',
        ],
      },
      {
        name: 'permissions.report.saleOfProducts',
        selected: watchedPermissions.report.saleOfProducts,
        dependsOn: [
          'permissions.analytics.get',
          'permissions.movement.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
          'permissions.product.get',
          'permissions.transaction.get',
          'permissions.booking.get',
          'permissions.client.get',
          'permissions.schedule.get',
          'permissions.account.get',
        ],
      },
      {
        name: 'permissions.report.saleOfCards',
        selected: watchedPermissions.report.saleOfCards,
        dependsOn: [
          'permissions.analytics.get',
          'permissions.movement.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
          'permissions.product.get',
          'permissions.transaction.get',
          'permissions.booking.get',
          'permissions.client.get',
          'permissions.schedule.get',
          'permissions.account.get',
        ],
      },
      {
        name: 'permissions.report.saleOfServices',
        selected: watchedPermissions.report.saleOfServices,
        dependsOn: [
          'permissions.analytics.get',
          'permissions.movement.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
          'permissions.product.get',
          'permissions.transaction.get',
          'permissions.booking.get',
          'permissions.client.get',
          'permissions.schedule.get',
          'permissions.account.get',
        ],
      },
      {
        name: 'permissions.report.revenueExpenses',
        selected: watchedPermissions.report.revenueExpenses,
        dependsOn: [
          'permissions.analytics.get',
          'permissions.movement.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
          'permissions.product.get',
          'permissions.transaction.get',
          'permissions.booking.get',
          'permissions.client.get',
          'permissions.schedule.get',
          'permissions.account.get',
        ],
      },
      {
        name: 'permissions.report.revenueByAccount',
        selected: watchedPermissions.report.revenueByAccount,
        dependsOn: [
          'permissions.analytics.get',
          'permissions.movement.get',
          'permissions.employee.get',
          'permissions.service.get',
          'permissions.location.get',
          'permissions.product.get',
          'permissions.transaction.get',
          'permissions.booking.get',
          'permissions.client.get',
          'permissions.schedule.get',
          'permissions.account.get',
        ],
      },
      {
        name: 'permissions.notification.get',
        selected: watchedPermissions.notification.get,
        dependsOn: [],
      },
      {
        name: 'permissions.notification.billing',
        selected: watchedPermissions.notification.billing,
        dependsOn: [],
      },
      {
        name: 'permissions.review.get',
        selected: watchedPermissions.review.get,
        dependsOn: ['permissions.booking.get'],
      },
      {
        name: 'permissions.review.set',
        selected: watchedPermissions.review.set,
        dependsOn: ['permissions.review.get'],
      },
    ],
    [watchedPermissions],
  )

  const checkOnSelect = ({
    permission,
    onConfirm,
  }: {
    permission: PermissionName
    onConfirm: () => void
  }) => {
    const neededPermissionsOnSelect: PermissionName[] = []

    const dependsOn = relations.find(({ name }) => name === permission)?.dependsOn ?? []

    dependsOn.forEach(permissionName => {
      const relation = relations.find(relation => permissionName === relation.name)

      if (relation) {
        if (!relation.selected) neededPermissionsOnSelect.push(relation.name)
      }
    })

    if (!neededPermissionsOnSelect.length) {
      onConfirm()
      return
    }

    showConfirmation({
      title: t('permissionsRelations.title'),
      description: (
        <div className={blockStyle}>
          <p className={textStyle}>{t('permissionsRelations.needed')}</p>

          <ul className="columns-2">
            {neededPermissionsOnSelect.map(permission => (
              <Checkbox
                key={permission}
                label={t(permission)}
                checked={true}
                disabled
                containerClassName={checkboxStyle}
              />
            ))}
          </ul>
        </div>
      ),
      onConfirm: () => {
        onConfirm()
        neededPermissionsOnSelect.forEach(permission => setValue(permission, true))
      },
    })
  }

  // TODO: improve types
  // should accept generic that contains names of blocks and makes all fields required to check
  const checkRelationBulk = ({ permissions, onConfirm }: CheckRelationBulkProps) => {
    const neededPermissionsOnSelectHash: Record<string, boolean> = {}

    for (const permission of permissions) {
      const dependsOn = relations.find(({ name }) => name === permission)?.dependsOn ?? []

      dependsOn.forEach(permissionName => {
        const relation = relations.find(
          relation =>
            permissionName === relation.name &&
            // if we already want to add this permission then no need to check
            !permissions.some(
              otherPermissionToSelect => otherPermissionToSelect === permissionName,
            ),
        )

        if (relation) {
          if (!relation.selected) {
            if (!neededPermissionsOnSelectHash[relation.name]) {
              neededPermissionsOnSelectHash[relation.name] = true
            }
          }
        }
      })
    }

    const neededPermissionsOnSelect = Object.keys(neededPermissionsOnSelectHash) as PermissionName[]
    if (!neededPermissionsOnSelect.length) {
      onConfirm()
      return
    }

    showConfirmation({
      title: t('permissionsRelations.title'),
      description: (
        <div className={blockStyle}>
          <p className={textStyle}>{t('permissionsRelations.needed')}</p>

          <ul className="columns-2">
            {neededPermissionsOnSelect.map(permission => (
              <Checkbox
                key={permission}
                label={t(permission)}
                checked={true}
                disabled
                containerClassName={checkboxStyle}
              />
            ))}
          </ul>
        </div>
      ),
      onConfirm: () => {
        onConfirm()
        neededPermissionsOnSelect.forEach(permission => setValue(permission, true))
      },
    })
  }

  const checkOnDeselect = ({
    permission,
    onConfirm,
  }: {
    permission: PermissionName
    onConfirm: () => void
  }) => {
    const neededForDeselectedPermission = relations
      .filter(({ dependsOn, selected }) => dependsOn.includes(permission) && selected)
      .map(({ name }) => name)

    if (!neededForDeselectedPermission.length) {
      onConfirm()
      return
    }

    showConfirmation({
      title: t('permissionsRelations.title'),
      description: (
        <div className={blockStyle}>
          <p className={textStyle}>{t('permissionsRelations.neededFor')}</p>

          <ul className="columns-2">
            {neededForDeselectedPermission.map(permission => (
              <Checkbox
                key={permission}
                label={t(permission)}
                checked={false}
                disabled
                containerClassName={checkboxStyle}
              />
            ))}
          </ul>
        </div>
      ),
      onConfirm: () => {
        onConfirm()
        neededForDeselectedPermission.forEach(permission => setValue(permission, false))
      },
    })
  }

  const checkRelation = ({ isSelect, onConfirm, permission }: CheckRelationProps) =>
    isSelect ? checkOnSelect({ permission, onConfirm }) : checkOnDeselect({ permission, onConfirm })

  return { confirmationModal, checkRelation, checkRelationBulk }
}

const textStyle = 'mb-4'
const checkboxStyle = 'text-gray-500 ml-4 mb-1 last:mb-2'
const blockStyle = 'w-256 overflow-auto'
