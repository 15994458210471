import {
  CardType,
  TRANSACTION_TYPES,
  useActivateGiftCard,
  useCreateTransactionToClientBalance,
  useFetchCardsByClientId,
  useFetchCurrentBranchTimezone,
  useFetchNotActivatedGiftCards,
} from '@expane/data'
import { createCurrentDate, formatFullDate } from '@expane/date'
import {
  getClientGiftCards,
  getIsGiftCardValidForActivation,
  getValidUntilGiftCardText,
  sortGiftCardByNotActivated,
} from '@expane/logic/cards'
import { useConvertNumberToMoney } from '@expane/logic/currency'
import { permissions } from '@expane/logic/permission'
import { Button, EmptyPlaceholder, Input, InputLabel, Table } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { ColumnDef } from '@tanstack/react-table'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoCardOutline, IoEyeOutline } from 'react-icons/io5'
import { store } from 'store'
import { useGiftCardSaleNotice } from 'widgets/GiftCardSaleNotice'
import { PLACEHOLDERS } from '@expane/logic/form'

interface Props {
  clientId: number
  disabled: boolean
  myPermissions: string[]
}

export const ClientDialogGiftCardsTab: FC<Props> = observer(
  ({ clientId, disabled, myPermissions }) => {
    const branchId = store.branch.branchId

    const dateFormatting = useDateFormatting()
    const [openSnackbar] = useSnackbar()

    const { t } = useTranslation()
    const convertNumberToMoney = useConvertNumberToMoney(branchId)

    const timezone = useFetchCurrentBranchTimezone(branchId)
    const { giftCardSaleNotice, openGiftCardSaleNotice } = useGiftCardSaleNotice()

    const { data: notActivatedGiftCards, isLoading } = useFetchNotActivatedGiftCards(
      timezone,
      branchId,
    )
    const { data: clientCards } = useFetchCardsByClientId(clientId, timezone, branchId)

    const { mutateAsync: transactionToAccount } = useCreateTransactionToClientBalance()
    const { mutateAsync: activatedGiftCard } = useActivateGiftCard()

    const clientGiftCards = getClientGiftCards(clientCards)
    const sortedGiftCards = sortGiftCardByNotActivated(clientGiftCards)

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
      setValue,
    } = useForm<{ code: string }>({
      defaultValues: { code: '' },
    })
    const columns = useMemo<ColumnDef<CardType>[]>(
      () => [
        {
          accessorKey: 'cardTemplate.name',
          header: t('name'),
          cell: data => data.getValue(),
          size: 400,
        },
        {
          accessorKey: 'cardTemplate.price',
          header: () => <span className="w-full text-right">{t('amount')}</span>,
          cell: data => (
            <div className="text-right">{convertNumberToMoney(data.getValue<number>())}</div>
          ),
        },
        {
          accessorKey: 'activatedAt',
          header: t('dateOfActivation'),
          cell: data => {
            const date = data.getValue<Date | null>()
            return date ? dateFormatting('historyDate', data.getValue<Date>()) : '-'
          },
        },
        {
          accessorKey: 'createdAt',
          header: t('dateOfSale'),
          cell: data => {
            const date = data.getValue<Date | null>()
            return date ? formatFullDate(data.getValue<Date>()) : '-'
          },
        },
        {
          id: 'deactivatedAt',
          header: t('validUntil'),
          cell: data => {
            const giftCard = data.row.original

            return t(getValidUntilGiftCardText(giftCard))
          },
        },
        {
          id: 'code',
          cell: data => {
            const { id, createdAt, cardPeriod, canceledDate, activatedAt } = data.row.original

            const isGiftCardValidForActivation = getIsGiftCardValidForActivation({
              createdAt,
              cardPeriod,
              timezone,
              canceledDate,
              activatedAt,
            })

            return !activatedAt && isGiftCardValidForActivation ? (
              <button
                className={buttonStyles}
                onClick={() => openGiftCardSaleNotice(clientId, [id])}
              >
                <IoEyeOutline className="mr-1" size="0.8rem" />
                {t('seeCode')}
              </button>
            ) : (
              '-'
            )
          },
        },
      ],
      [clientId, convertNumberToMoney, dateFormatting, openGiftCardSaleNotice, t, timezone],
    )

    const mutateClientInfo: SubmitHandler<{ code: string }> = async data => {
      if (!timezone || !branchId) {
        openSnackbar(t('submitError'), 'error')
        return
      } else {
        const giftCardForActivation = notActivatedGiftCards?.find(card => card.code === data.code)

        if (giftCardForActivation) {
          const isGiftCardValidForActivation = getIsGiftCardValidForActivation({
            createdAt: giftCardForActivation.createdAt,
            cardPeriod: giftCardForActivation.cardPeriod,
            timezone,
            canceledDate: giftCardForActivation.canceledDate,
            activatedAt: giftCardForActivation.activatedAt,
          })

          if (isGiftCardValidForActivation) {
            try {
              await activatedGiftCard({
                id: giftCardForActivation.id,
                clientId,
                activatedAt: createCurrentDate(timezone),
              })

              await transactionToAccount({
                type: TRANSACTION_TYPES.activateGiftCard.id,
                amount: giftCardForActivation.cardTemplate.price,
                clientId,
                branchId,
              })

              setValue('code', '')

              openSnackbar(t('giftCardActivation.isActivated'), 'success')
            } catch (error) {
              openSnackbar(t('submitError'), 'error', 3000)
            }
          } else openSnackbar(t('giftCardActivation.isExpired'), 'error')
        } else openSnackbar(t('giftCardActivation.wrongCode'), 'error')
      }
    }

    if (isLoading) return null

    const isCardActivateAllowed =
      myPermissions?.includes(permissions.card.set) &&
      myPermissions?.includes(permissions.transaction.set)

    return (
      <div className="flex flex-col h-full">
        {isCardActivateAllowed && !disabled && (
          <>
            <InputLabel label={t('giftCardActivation.code')} required />

            <div className="flex">
              <Controller
                name="code"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value }, formState }) => (
                  <Input
                    placeholder={PLACEHOLDERS.giftCardCode}
                    required
                    errorMessage={{
                      isShown: Boolean(formState.errors.code),
                      text: t('formError.required'),
                    }}
                    containerClassName="w-2/5"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <Button
                type="outline"
                className="ml-2"
                onClick={handleSubmit(mutateClientInfo)}
                disabled={isSubmitting}
              >
                {t('activate')}
              </Button>
            </div>
          </>
        )}

        {sortedGiftCards?.length ? (
          <Table columns={columns} data={sortedGiftCards} isLoading={isLoading} />
        ) : (
          <EmptyPlaceholder Icon={IoCardOutline} text={t('noGiftCards')} />
        )}
        {giftCardSaleNotice}
      </div>
    )
  },
)

const buttonStyles = `flex rounded-md border-2 font-medium focus:outline-none focus:ring-2 
focus:ring-offset-2 leading-3 text-xs focus:ring-primary-400 text-primary-500 px-1 py-1 m-1 
border-primary-400 hover:border-primary-600 hover:text-primary-600 ml-auto`
