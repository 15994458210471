import { FC, PropsWithChildren } from 'react'
import { CloseButton, SaveButtonWithoutBillingCheck } from '../Button/buttons'
import { Dialog } from '../Dialog'
import { InputHeight } from '../Input'
import { InputLabel } from '../InputLabel'
import { Modal } from '../Modal'

const MAP_PLACEHOLDER_WIDTH = {
  small: 'w-12',
  normal: 'w-28',
  full: 'w-full',
} as const
type PlaceholderStringWidth = keyof typeof MAP_PLACEHOLDER_WIDTH

export type PlaceholderStringProps = {
  // TODO: Розібратися чому eslint тут вважає що вони не використовуються
  // eslint-disable-next-line react/no-unused-prop-types
  width?: PlaceholderStringWidth
  className?: string
  // eslint-disable-next-line react/no-unused-prop-types
  isRight?: boolean
}

export const PlaceholderString: FC<PlaceholderStringProps> = ({
  className = '',
  width = 'normal',
  isRight = false,
}) => {
  return (
    <div className={'w-full ' + className}>
      <div
        className={
          MAP_PLACEHOLDER_WIDTH[width] +
          (isRight ? ' ml-auto ' : '') +
          ' relative m-0.5 h-4 rounded bg-placeholder overflow-hidden '
        }
      >
        <div className="animate-placeholder absolute top-0 -left-full w-full h-full bg-placeholder-gradient" />
      </div>
    </div>
  )
}

export type PlaceholderInputProps = PlaceholderStringProps & {
  label?: string
  // eslint-disable-next-line react/no-unused-prop-types
  height?: InputHeight
}
export const PlaceholderInput: FC<PlaceholderInputProps> = ({
  label,
  className,
  width = 'normal',
  height = 'normal',
  isRight,
}) => {
  let inputStyle =
    'w-full border-2 rounded-lg px-3 flex items-center border-gray-100 dark:border-gray-500 '

  if (height === 'normal') inputStyle += 'h-9.5 '
  if (height === 'medium') inputStyle += 'h-8 '
  if (height === 'small') inputStyle += 'h-7 '

  return (
    <div className={className}>
      {(label?.length ?? 0) > 0 && <InputLabel label={label} />}
      <div className={inputStyle}>
        <PlaceholderString width={width} isRight={isRight} />
      </div>
    </div>
  )
}

type PlaceholderTextareaProps = PlaceholderInputProps & {
  rows?: number
}

export const PlaceholderTextarea: FC<PlaceholderTextareaProps> = ({
  label,
  className,
  rows = 4,
}) => {
  // якщо треба буде більше аніж 5 рядків треба прописувати стилі в HEIGHT_STYLES
  const textareaHeight = rows >= 1 && rows <= 5 ? getTextareaHeight(rows) : getTextareaHeight(4)

  return (
    <div className={className}>
      {(label?.length ?? 0) > 0 && <InputLabel label={label} />}
      <div
        className={`w-full ${textareaHeight} border-2 rounded-lg px-3 flex pt-2  border-gray-100 dark:border-gray-500`}
      >
        <PlaceholderString />
      </div>
    </div>
  )
}

const TEXT_HEIGHT = 20 // px
const PADDING_BORDER_HEIGHT = 20 // px

const getTextareaHeight = (rows: number) => `h-[${rows * TEXT_HEIGHT + PADDING_BORDER_HEIGHT}px]`
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HEIGHT_STYLES = 'h-[40px] h-[60px] h-[80px] h-[100px] h-[120px]'

export type CommonPlaceholderDialogProps = {
  closeDialog: () => void
}
type PlaceholderDialogProps = CommonPlaceholderDialogProps & { title: string; className?: string }

export const PlaceholderDialog: FC<PropsWithChildren<PlaceholderDialogProps>> = ({
  closeDialog,
  title,
  className,
  children,
}) => {
  return (
    <Modal close={closeDialog}>
      <Dialog>
        <Dialog.Title>{title}</Dialog.Title>

        <Dialog.Body className={className} children={children} />

        <Dialog.Footer>
          <SaveButtonWithoutBillingCheck disabled />
          <CloseButton onClick={closeDialog} />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}
