const client = {
  get: 'client.get',
  set: 'client.set',
  phone: 'client.phone',
  archive: 'client.archive',
} as const
const clientNote = { get: 'clientNote.get', set: 'clientNote.set' } as const
const clientMessage = { get: 'clientMessage.get', set: 'clientMessage.set' } as const
const business = { set: 'business.set' } as const
const billingInfo = { get: 'billingInfo.get', set: 'billingInfo.set' } as const
const branch = { set: 'branch.set' } as const
const employee = {
  get: 'employee.get',
  set: 'employee.set',
  email: 'employee.email',
  archive: 'employee.archive',
} as const
const booking = {
  get: 'booking.get',
  set: 'booking.set',
  setOwn: 'booking.setOwn',
  editPast: 'booking.editPast',
} as const
const bookingStatus = {
  set: 'bookingStatus.set',
  unset: 'bookingStatus.unset',
} as const
const service = {
  get: 'service.get',
  set: 'service.set',
  archive: 'service.archive',
} as const
const account = { get: 'account.get', set: 'account.set' } as const
const storage = { get: 'storage.get', set: 'storage.set' } as const
const salary = { get: 'salary.get', set: 'salary.set' } as const
const rolePermission = { set: 'rolePermission.set' } as const
const inventory = { get: 'inventory.get', set: 'inventory.set' } as const
const product = { get: 'product.get', set: 'product.set', archive: 'product.archive' } as const
const schedule = {
  get: 'schedule.get',
  set: 'schedule.set',
  editPast: 'schedule.editPast',
} as const
const supplier = { get: 'supplier.get', set: 'supplier.set' } as const
const card = { get: 'card.get', set: 'card.set' } as const
const waitingList = { get: 'waitingList.get', set: 'waitingList.set' } as const
const report = {
  revenueExpenses: 'report.revenueExpenses',
  revenueByAccount: 'report.revenueByAccount',
  saleOfServices: 'report.saleOfServices',
  saleOfCards: 'report.saleOfCards',
  saleOfProducts: 'report.saleOfProducts',
  leadsOfClients: 'report.leadsOfClients',
  clientsVisits: 'report.clientsVisits',
  incomingPayments: 'report.incomingPayments',
  outgoingPayments: 'report.outgoingPayments',
} as const
const review = { get: 'review.get', set: 'review.set' } as const
const lead = { set: 'lead.set' } as const
const tag = { set: 'tag.set' } as const
const revenueReason = { set: 'revenueReason.set' } as const
const expensesReason = { set: 'expensesReason.set' } as const
const movement = { get: 'movement.get', set: 'movement.set' } as const
const transaction = {
  get: 'transaction.get',
  set: 'transaction.set',
  editPast: 'transaction.editPast',
  betweenAccounts: 'transaction.betweenAccounts',
  setRefund: 'transaction.setRefund',
} as const
const timeOff = { get: 'timeOff.get', set: 'timeOff.set' } as const
const timeOffReason = { set: 'timeOffReason.set' } as const
const location = { get: 'location.get', set: 'location.set', archive: 'location.archive' } as const
const analytics = { get: 'analytics.get' } as const

export const onlyOwnersPermissions = {
  owner: {
    set: 'owner.set',
  },
} as const

export const OWNER_ROLE_ID = 'owner'

export const permissions = {
  client,
  clientNote,
  clientMessage,
  lead,
  tag,
  card,
  booking,
  bookingStatus,
  waitingList,
  location,
  employee,
  timeOff,
  timeOffReason,
  schedule,
  salary,
  service,
  transaction,
  movement,
  account,
  revenueReason,
  expensesReason,
  storage,
  product,
  inventory,
  supplier,
  report,
  review,
  analytics,
  rolePermission,
  business,
  billingInfo,
  branch,
} as const
