import {
  commonSelectClearIconStyle,
  commonSelectIconStyle,
  ErrorMessage,
  ErrorMessageType,
  FadeInTranslateTop,
  filterBySearchValue,
  getCommonSelectButtonStyle,
  Hint,
  Input,
  InputLabel,
  modalsHTMLElement,
  SelectDropDownItem,
  useHandleClickOutside,
  usePopupOpenState,
} from '@expane/ui'
import { offset, useFloating } from '@floating-ui/react-dom'
import { ChangeEvent, FC, KeyboardEvent, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { IoCheckmark, IoChevronDownOutline, IoClose, IoFilter, IoSearch } from 'react-icons/io5'

export interface MultiSelectProps {
  items: SelectDropDownItem[]
  onItemSelect: (value: SelectDropDownItem[]) => void
  selectedItems: SelectDropDownItem[] | undefined
  placeholder?: string
  dropdownInputPlaceholder?: string
  label?: string
  hint?: string
  className?: string
  errorMessage?: ErrorMessageType
  required?: boolean
  disabled?: boolean
  // размер выпадающего списка
  popupHeight?: 'small' | 'big'
  // высота самого ипута
  height?: 'small' | 'normal'
  // для storybook
  customModal?: HTMLElement | null
  displayedItems?: DisplayedItemsOptions
  isFilter?: boolean
  onPlusClick?: () => void
  onSearchValueChange?: (value: string) => void
  // Використовується там де прокидуються тільки знайдені items(пошук клієнтів в інших філіях)
  customSearchPlaceholder?: string
}

export const MultiSelect: FC<MultiSelectProps> = ({
  items,
  onItemSelect,
  selectedItems,
  placeholder,
  dropdownInputPlaceholder,
  className,
  label,
  hint,
  errorMessage,
  required,
  disabled = false,
  popupHeight = 'small',
  customModal,
  displayedItems = 3,
  isFilter = false,
  onPlusClick,
  height = 'normal',
  onSearchValueChange,
  customSearchPlaceholder,
}) => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const [searchValue, setSearchValue] = useState<string>('')
  const handleChangeSearchValue = (value: string) => {
    onSearchValueChange?.(value)
    setSearchValue(value)
  }
  const [activeLink, setActiveLink] = useState<number | undefined>(undefined)

  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const searchInputRef = useRef<HTMLInputElement | null>(null)
  const [filterRef, setFilterRef] = useState<HTMLDivElement | null>(null)

  const { t } = useTranslation()

  const translatedDropdownInputPlaceholder = dropdownInputPlaceholder ?? t('search')

  const closeList = () => {
    handleChangeSearchValue('')
    closePopup()
  }

  const openList = () => {
    setActiveLink(undefined)
    openPopup()
  }

  useHandleClickOutside(
    [buttonRef.current, filterRef],
    () => {
      closeList()
    },
    isOpen,
  )

  const { reference, floating, strategy, x, y } = useFloating({
    placement: 'bottom-start',
    middleware: [offset(5)],
  })
  // We need direct control of element, so we store our own ref and sync it with useFloating
  useLayoutEffect(() => {
    reference(buttonRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference, buttonRef.current])
  useLayoutEffect(() => {
    floating(filterRef)
  }, [filterRef, floating])

  const filteredData = filterBySearchValue(items, searchValue).sort(i => {
    return checkIfItemIsSelected(selectedItems, i) ? -1 : 1
  })

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (e.key === 'Tab') {
      buttonRef.current?.focus()
      closeList()
    }
    if (e.key === 'ArrowDown') {
      if (activeLink === undefined) return setActiveLink(0)
      if (activeLink === filteredData.length - 1) {
        setActiveLink(undefined)
        return searchInputRef.current?.focus()
      }
      setActiveLink(activeLink + 1)
    }
    if (e.key === 'ArrowUp') {
      if (activeLink === undefined) return setActiveLink(filteredData.length - 1)
      if (activeLink === 0) {
        setActiveLink(undefined)
        return searchInputRef.current?.focus()
      }

      setActiveLink(activeLink - 1)
    }
    if (e.key === 'Enter') {
      const selectedDropDownItem = filteredData.find((el, index) => activeLink === index)
      if (selectedDropDownItem !== undefined) handleSelectedItems(selectedDropDownItem)
    }
    if (e.key === 'Escape' && searchValue === '') {
      buttonRef.current?.focus()
      closeList()
    }
    if (e.key === 'Escape') {
      handleChangeSearchValue('')
    }
  }

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    handleChangeSearchValue(e.target.value)
  }

  const handleSelectedItems = (item: SelectDropDownItem) => {
    if (item.disabled) return

    if (checkIfItemIsSelected(selectedItems, item)) {
      onItemSelect(selectedItems?.filter((el: SelectDropDownItem) => el.id !== item?.id) ?? [])
    } else {
      if (selectedItems !== undefined) onItemSelect([...selectedItems, item])
      else onItemSelect([item])
    }
  }

  const filterBtnStyle = getCommonSelectButtonStyle({
    height,
    isOpen,
    isDisabled: disabled,
    isError: Boolean(errorMessage?.isShown),
  })
  let filterIconStyle = commonSelectIconStyle

  if (isOpen) {
    filterIconStyle += ' text-primary-500'
  }

  useEffect(() => {
    // анимация не работает если у инпута autoFocus
    if (isOpen) searchInputRef.current?.focus()
  }, [isOpen])

  return (
    <div className={className}>
      <div className="max-w-full">
        <InputLabel label={label} hint={hint} required={required} onPlusClick={onPlusClick} />
        <button
          ref={buttonRef}
          className={filterBtnStyle}
          onClick={() => {
            if (!isOpen) openList()
          }}
          onFocus={() => {
            if (!isOpen) openList()
          }}
          onKeyDown={e => {
            if (isOpen) {
              if (e.key === 'Tab') {
                closeList()
              } else {
                searchInputRef.current?.focus()
              }
            }
          }}
          disabled={disabled}
        >
          {(selectedItems?.length ?? 0) === 0 ? (
            <>
              <span className="text-gray-300">{disabled ? '' : placeholder}</span>
              <div className={filterIconStyle}>
                {isFilter ? (
                  <IoFilter size="1.2rem" />
                ) : (
                  <IoChevronDownOutline
                    size="1.2rem"
                    className={'transition-transform ease-out' + (isOpen ? ' rotate-180' : '')}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              <div className="grow text-left line-clamp-1 text-gray-500">
                {selectedItems?.map(item => (
                  <SelectedItem
                    key={item.id}
                    label={item.name}
                    itemWidth={getWidthItem(displayedItems, selectedItems.length)}
                    onClick={() => handleSelectedItems(item)}
                  />
                ))}
              </div>

              <div
                onClick={e => {
                  e.stopPropagation()
                  onItemSelect(
                    selectedItems?.filter(selectedItem => selectedItem.disabled === true) ?? [],
                  )
                }}
                className={commonSelectClearIconStyle}
              >
                <IoClose size="1.2rem" />
              </div>
            </>
          )}
        </button>
        <ErrorMessage errorMessage={errorMessage} />

        {isOpen &&
          createPortal(
            <div
              ref={setFilterRef}
              onKeyDown={handleKeyDown}
              tabIndex={0}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
              }}
            >
              <FadeInTranslateTop
                className="pl-1 pt-1 border-2 border-primary-400 rounded-md box-border bg-block"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="items-list"
                style={{ minWidth: buttonRef.current?.offsetWidth }}
              >
                <Input
                  placeholder={translatedDropdownInputPlaceholder}
                  value={searchValue}
                  onChange={handleInput}
                  containerClassName="mb-1 mr-1"
                  Icon={IoSearch}
                  height="medium"
                  ref={searchInputRef}
                />

                <ul
                  id="multi_select_popup"
                  className={
                    (popupHeight === 'small' ? 'max-h-45' : 'max-h-90') + ' overflow-y-auto pb-1'
                  }
                >
                  {filteredData.length > 0 ? (
                    filteredData.map((item, index) => (
                      <Item
                        key={item.id + item.name + index}
                        isSelected={checkIfItemIsSelected(selectedItems, item)}
                        isActive={index === activeLink}
                        label={item.name}
                        onMouseEnter={() => {
                          if (index !== activeLink) setActiveLink(index)
                        }}
                        searchValue={searchValue}
                        onClick={() => {
                          handleSelectedItems(item)
                        }}
                        isDisabled={item.disabled}
                        warningMessage={item.warningMessage}
                        modalElement={customModal ?? modalsHTMLElement}
                      />
                    ))
                  ) : (
                    <li className="text-sm text-center text-gray-500 flex items-left px-2 py-2 cursor-pointer">
                      {customSearchPlaceholder
                        ? customSearchPlaceholder
                        : items.length === 0
                        ? t('noItems')
                        : t('nothingFoundForRequest')}
                    </li>
                  )}
                </ul>
              </FadeInTranslateTop>
            </div>,
            customModal ?? modalsHTMLElement,
          )}
      </div>
    </div>
  )
}

interface ItemProps {
  isSelected?: boolean
  onMouseEnter: () => void
  label: string
  onClick: () => void
  searchValue: string
  modalElement: HTMLElement
  isActive?: boolean
  isDisabled: boolean | undefined
  warningMessage: string | undefined
}

const Item: FC<ItemProps> = ({
  isSelected = false,
  onMouseEnter,
  label,
  onClick,
  searchValue,
  isActive = false,
  isDisabled = false,
  warningMessage,
  modalElement,
}) => {
  const startIndex: number = label.toLowerCase().indexOf(searchValue.toLowerCase())
  const endIndex: number = startIndex + searchValue.length

  const itemRef = useRef<HTMLLIElement>(null)

  useEffect(() => {
    if (isActive && itemRef.current) {
      // ищем родителя со скролом
      const scrollableParent = itemRef.current?.closest('#multi_select_popup')
      if (scrollableParent) {
        // определяем размеры и позицию родителя и выбранного айтема относительно viewport
        const itemPosition = itemRef.current.getBoundingClientRect()
        const parentPosition = scrollableParent.getBoundingClientRect()

        // сравниваем расположение айтема относительно родителя, чтобы понимать куда скролим вверх или вниз
        if (itemPosition.bottom > parentPosition.bottom)
          itemRef.current.scrollIntoView({ block: 'end' })
        if (itemPosition.top < parentPosition.top)
          itemRef.current.scrollIntoView({ block: 'start' })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, itemRef.current])

  return (
    <li
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      className={addAdditionalStyles(isSelected, isActive, isDisabled)}
      ref={itemRef}
    >
      <div className="flex items-center">
        {startIndex !== -1 && startIndex !== endIndex ? (
          <span className="shrink overflow-hidden truncate">
            {label.slice(0, startIndex)}
            <span className="bg-primary-100 rounded-sm">{label.slice(startIndex, endIndex)}</span>
            {label.slice(endIndex)}
          </span>
        ) : (
          <span className="shrink overflow-hidden truncate">{label}</span>
        )}
        {warningMessage !== undefined && (
          <Hint type="warning" customModal={modalElement} iconSize="0.9rem" className="ml-0.5">
            {warningMessage}
          </Hint>
        )}
      </div>
      {isSelected && (
        <div className="w-min shrink">
          <IoCheckmark size="1.2rem" />
        </div>
      )}
    </li>
  )
}

const checkIfItemIsSelected = (
  array: SelectDropDownItem[] | undefined,
  item: SelectDropDownItem | undefined,
) => Boolean(array?.find(el => item?.id === el.id))

const addAdditionalStyles = (isSelected: boolean, isActive: boolean, isDisabled: boolean) => {
  let className =
    'flex justify-between text-sm items-center pl-3 pr-2 mr-1 min-h-7 cursor-pointer rounded-md'

  if (isDisabled) {
    className += ' text-gray-300 dark:text-gray-500'
  } else {
    className += ' text-label-color'
    if (isSelected) className += ' text-primary-500 dark:text-primary-500 font-medium'
    if (isActive) className += ' bg-primary-50 dark:bg-primary-500/20 rounded-md text-primary-600'
  }

  return className
}

interface SelectedItemProps {
  label: string
  onClick: () => void
  itemWidth: string
}

export const SelectedItem: FC<SelectedItemProps> = ({ label, itemWidth, onClick }) => {
  return (
    <div className={itemWidth + ' inline-block'}>
      <div className="flex justify-between items-center text-sm items-left mr-0.5 bg-accent text-label-color rounded-md">
        <span className="pl-0.5 py-0.5 truncate">{label}</span>
        <div className="shrink">
          <IoClose
            size="1rem"
            className="px-0.5 hover:text-error-500"
            onClick={e => {
              e.stopPropagation()
              onClick()
            }}
          />
        </div>
      </div>
    </div>
  )
}

export type DisplayedItemsOptions = 1 | 2 | 3 | 4 | 5 | 'fits'
export const getWidthItem = (displayedItems: DisplayedItemsOptions, amountItems: number) => {
  if (amountItems < 5 && Number(displayedItems) >= amountItems) return 'max-w-full'
  if (displayedItems === 2) return 'max-w-46/100'
  if (displayedItems === 3) return 'max-w-31/100'
  if (displayedItems === 4) return 'max-w-23/100'
  if (displayedItems === 5) return 'max-w-18/100'
  return 'max-w-90/100'
}
