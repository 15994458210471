import { FC } from 'react'
import { ReviewType, useAcceptReview, useArchiveReview } from '@expane/data'
import { Button, ButtonSize, Tag, useShowConfirmationPopup } from '@expane/ui'
import { IoCheckmarkDoneSharp, IoTrashOutline } from 'react-icons/io5'
import { useSnackbar } from '@expane/widgets'
import { useTranslation } from 'react-i18next'

export const ReviewActions: FC<{
  review: ReviewType
  size?: ButtonSize
}> = ({ review, size }) => {
  const { t } = useTranslation()

  const [openSnackbar] = useSnackbar()

  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

  const { mutateAsync: mutateAcceptReview, isLoading: isLoadingAcceptReview } = useAcceptReview()
  const { mutateAsync: mutateArchiveReview, isLoading: isLoadingDeleteReview } = useArchiveReview()

  const isLoading = isLoadingAcceptReview || isLoadingDeleteReview

  const acceptReview = async () => {
    const result = await mutateAcceptReview(review.id)

    if (result?.updateReviewById?.id) openSnackbar(t('reviews.approveSuccess'), 'success')
    else openSnackbar(t('submitError'), 'error')
  }

  const archiveReview = async () => {
    const result = await mutateArchiveReview(review.id)

    if (result?.updateReviewById?.id) openSnackbar(t('reviews.archiveSuccess'), 'success')
    else openSnackbar(t('submitError'), 'error')
  }

  if (review.archived) return <Tag name={t('archival')} color={'red-500'} />

  return (
    <div className="flex gap-2">
      {!review.approved && (
        <Button
          type={'outline'}
          Icon={IoCheckmarkDoneSharp}
          onClick={acceptReview}
          disabled={isLoading}
          size={size}
        />
      )}

      <Button
        className="ml-auto"
        type="danger"
        Icon={IoTrashOutline}
        onClick={() => {
          showConfirmation({
            title: t('reviews.archiveConfirmationTitle'),
            description: t('reviews.archiveConfirmation'),
            onConfirm: archiveReview,
          })
        }}
        disabled={isLoading}
        size={size}
      />

      {confirmationModal}
    </div>
  )
}
